import React, { useState } from 'react'
import { Form, Button, Alert } from 'react-bootstrap';
import logo from "../../assets/images/adm_logo.png";
import Layout from '../../components/layout/Layout_login'
import AuthService from '../../services/auth.service';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllRoles } from '../../feature/Roles_Permission/roles';
import { setLoading } from '../../feature/loading/loading';
import { setMessage } from '../../feature/message/message';
import message from '../../utils/message';
const Login = () => {
    const loadingmessage= useSelector((state) => state.message.message);
    const isLoading = useSelector((state) => state.loading.loading);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [loginError, setLoginError] = useState('');
    const dispatch = useDispatch();
      const navigate = useNavigate();

    const validateEmail = () => {
        if (!email) {
            setEmailError('Email is required');
            return false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            setEmailError('Invalid email format');
            return false;
        } else {
            setEmailError('');
            return true;
        }
    };

    const validatePassword = () => {
        if (!password) {
            setPasswordError('Password is required');
            return false;
        } else if (password.length < 6) {
            setPasswordError('Password must be at least 6 characters long');
            return false;
        } else {
            setPasswordError('');
            return true;
        }
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        const isEmailValid = validateEmail();
        const isPasswordValid = validatePassword();

        if (isEmailValid && isPasswordValid) {
        try{
            dispatch(setLoading(true));
            dispatch(setMessage(message.loading));
            const user=  await AuthService.login(email,password)
            // console.log(user)

              const userId=user.user.user.id
           await dispatch(fetchAllRoles({userId})); 
            navigate("/clients")
            setLoginError("")
        }catch(err){
            setLoginError(err.response.data.error)
            console.log(err)
        }finally {
            dispatch(setLoading(false));
      
          }
       



        
        
        } else {
            setLoginError('Please fix the errors before submitting');
        }

       
    };
    return (
<Layout>
        <div className='login' style={{ height: '100vh' }}>
            <div className="login-form-wrapper">
                <div className="login-card">
                    <img src={logo} width="160" className="logo" alt="Logo" />
                    <h3>Sign in</h3>
                    {loginError && <Alert variant="danger">{loginError}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className='form-group' controlId="userSigninLogin">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                isInvalid={!!emailError}
                            />
                            <Form.Control.Feedback type="invalid">
                                {emailError}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className='form-group' controlId="userSigninPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                isInvalid={!!passwordError}
                            />
                            <Form.Control.Feedback type="invalid">
                                {passwordError}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* <Button variant="primary" type="submit">
                            Sign in
                        </Button> */}

                        <Button variant="primary" type="submit" disabled={isLoading}>
                        {isLoading?(loadingmessage):("Sign in")}
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    </Layout>
    )
}

export default Login