import axios from 'axios';
const API_URL = `${process.env.REACT_APP_API_URL}/api/admin/v1/permission/`;
const getPermission = async (user_id ) => {

    const res = await axios.get(API_URL + "getRoles",{  params: {
        user_id: user_id,

    }},{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;

}




const  PermissionService={
    
    getPermission
 

}

export default PermissionService