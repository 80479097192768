import React from 'react';
import Cookies from 'js-cookie';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const getAccessToken = () => {
  // Get the 'admone' cookie which contains both tokens
  const admoneCookie = Cookies.get('admone');
  if (admoneCookie) {
    // Parse the cookie to extract the tokens object
    const tokens = JSON.parse(admoneCookie);
    return tokens.accessToken;
  }
  return null;
};

const ProtectedRoutes = () => {
  const location = useLocation();
  const token = getAccessToken();
  const isAuthenticated = !!token;

  const roles = useSelector((state) => state.Roles.roles);
  const permissions = roles.permissions || [];

  if (!isAuthenticated) {
    // Redirect to login page if not authenticated
    return <Navigate to="/login" />;
  }

  if (location.pathname === '/') {
    // Redirect to '/clients' if the path is '/'
    return <Navigate to="/clients" />;
  }

  // if (location.pathname =='/chats' && !permissions.includes('chats')) {
  //   // Redirect back to the previous route if the permission is missing
  //   return <Navigate to={-1} replace />;
  // }


  // Render the child routes if authenticated
  return <Outlet />;
};

export default ProtectedRoutes;
