// import { jwtDecode } from 'jwt-decode';

// import Cookies from 'js-cookie';

// // Function to get user ID (sub) from access token
// export const getUserIdFromToken = () => {
//     const userToken = Cookies.get('accessToken');
    
//     if (!userToken) {
//         console.log('No access token found');
//         return null;
//     }
    
//     try {
//         const decodedToken = jwtDecode(userToken);
//         return decodedToken.sub;
//     } catch (error) {
//         console.error('Failed to decode token:', error);
//         return null;
//     }
// };


import { jwtDecode } from 'jwt-decode'; // Correct import for jwt-decode library
import Cookies from 'js-cookie';

// Function to get user ID (sub) from access token
export const getUserIdFromToken = () => {
    const admoneCookie = Cookies.get('admone');
    
    if (!admoneCookie) {
        console.log('No admone cookie found');
        return null;
    }
    
    try {
        // Parse the admone cookie to get the tokens object
        const tokens = JSON.parse(admoneCookie);
        const userToken = tokens.accessToken;

        if (!userToken) {
            console.log('No access token found in admone cookie');
            return null;
        }
        
        // Decode the token to get the user ID (sub)
        const decodedToken = jwtDecode(userToken);
        return decodedToken.sub;
    } catch (error) {
        console.error('Failed to decode access token:', error.message);
        return null;
    }
};

export const getFullNameFromToken = () => {
    const admoneCookie = Cookies.get('admone');
    
    if (!admoneCookie) {
        console.log('No admone cookie found');
        return null;
    }
    
    try {
        // Parse the admone cookie to get the tokens object
        const tokens = JSON.parse(admoneCookie);
        const userToken = tokens.accessToken;

        if (!userToken) {
            console.log('No access token found in admone cookie');
            return null;
        }
        
        // Decode the token to get the user ID (sub)
        const decodedToken = jwtDecode(userToken);
        return decodedToken.fullname;
    } catch (error) {
        console.error('Failed to decode access token:', error.message);
        return null;
    }
};
