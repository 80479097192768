import axios from 'axios';
const API_URL = `${process.env.REACT_APP_API_URL}/admin/api/clients/`;
const getShareholderDetails= async (id ) => {

    const res = await axios.get(API_URL + "shareHolderDetails/"+id)

    return res.data;

}



const shareholderService = {
 
    getShareholderDetails
   
  }
  
  export default shareholderService;