// fileIconUtils.js
import excelicon from '../assets/images/file_icons/xls.png';
import pdficon from '../assets/images/file_icons/pdf.png';
import jpgicon from '../assets/images/file_icons/jpg.png';
import docicon from '../assets/images/file_icons/doc.png';
import pngicon from '../assets/images/file_icons/png.png';
import txticon from '../assets/images/file_icons/txt.png';
import ppticon from '../assets/images/file_icons/ppt.png';
import fileicon from '../assets/images/file_icons/file.png';
// Import other icons as needed

export const getFileIcon = (fileName) => {
  const extension = fileName.split('.').pop().toLowerCase();

  switch (extension) {
    case 'xlsx':
    case 'xls':
      return excelicon;
    case 'pptx':
    case 'ppt':
        return ppticon;
    case 'pdf':
      return pdficon;
    case 'jpg':
    case 'jpeg':
      return jpgicon;
    case 'png':
        return pngicon;
    case 'txt':
        return txticon;
    case 'docx':
    case 'doc':
      return docicon;
    // Add more extensions as needed
    default:
        return fileicon;
  }
};
