import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import shareholderService from "../../services/shareholder.service";


let initialState = {
   shareHolder: [],
 };


export const fetchShareHolder = createAsyncThunk(
    "shareHolder/fetchShareholder",
    async ({id},thunkAPI) => {
     
      try {
        const data = await shareholderService.getShareholderDetails(id);
      //   thunkAPI.dispatch(setMessage(data.message));
     
        return data;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  );




  const ShareHolderSlice = createSlice({
    name: 'shareHolder',
    initialState,
    reducers: { },
    extraReducers(builder) {
       


        builder.addCase(fetchShareHolder.fulfilled, (state, action) => {
     
       state.shareHolder =  action.payload;
      

      })

      
    }
})

// export const getAllclients = (state) => state.client.clients;
// export const getdba = (state) => state.dba.dba;
export default ShareHolderSlice.reducer
  