import React from 'react';


const Pagination = ({ currentPage, TotalPage, currentSet, setCurrentSet, setcurrentPage }) => {
  
  if (TotalPage <= 1) {
    return null; 
  }
  const handlePageChange = (page) => {
    if (page >= 1 && page <= TotalPage) {
      setcurrentPage(page);
    } 
  };

  const renderPagination = () => {
    const pages = [];
    const maxPagesToShow = 3;
    const totalSets = Math.ceil(TotalPage / maxPagesToShow);
    const startPage = (currentSet - 1) * maxPagesToShow + 1;
    const endPage = Math.min(currentSet * maxPagesToShow, TotalPage);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <a className="page-link" href="#!" onClick={() => handlePageChange(i)}>
            {i}
          </a>
        </li>
      );
    }

    return (
      <>
        {currentSet > 1 && (
          <li className="page-item">
            <a className="page-link" href="#!" onClick={() => {
              setCurrentSet(currentSet - 1);
              setcurrentPage((currentSet - 2) * maxPagesToShow + 1);
            }}>
              &laquo;
            </a>
          </li>
        )}
        {pages}
        {currentSet < totalSets && (
          <li className="page-item">
            <a className="page-link" href="#!" onClick={() => {
              setCurrentSet(currentSet + 1);
              setcurrentPage(currentSet * maxPagesToShow + 1);
            }}>
              &raquo;
            </a>
          </li>
        )}
      </>
    );
  };

  return (
    <nav aria-label="Page" className='pagination-wrapper' >
      <ul className="pagination pagination-sm">
        
        {renderPagination()}
        
      </ul>
    </nav>
  );
};

export default Pagination;
