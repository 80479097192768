import React, { useEffect } from "react";
import { documenticon } from "../utils/icons";
import { useNavigate } from "react-router-dom";



const NotificationItem = ({ title, timeAgo, senderName, message,modelId}) => {


const navigate=useNavigate()
  
  
  
  return(
  
  <div className={`notification-item ${title}`} onClick={() => navigate('/chats', { state: { NotificationThreadId: modelId } })}>
    <div className='notification-title'>{title}</div>
    <div className="notification-content">
      {/* <p className="message">{message}</p> */}
      <div className="message" dangerouslySetInnerHTML={{ __html: message }} />

      <p className="notification-time">By {senderName} | {timeAgo}</p>
    </div>
  </div>
  
)};

const Notifications = ({ notifications }) => (
  <div className="dropdown-menu dropdown-menu-end notifications-dropdown" data-bs-auto-close="outside" aria-labelledby="notificationsDropdown">
  <div className="card-body">
  <ul className="list-group list-group-flush">
    {notifications.map((notification, index) => (
      <li key={index} className="list-group-item">
      <NotificationItem key={index} {...notification} />
      </li>
    ))}
    </ul>
    </div>
  </div>
);

export default Notifications;
