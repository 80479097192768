import React, { useEffect, useState } from 'react'
import { Card,  Accordion, } from 'react-bootstrap';
import TaxAccountService from '../../../services/taxAccount.service';
import { useParams } from 'react-router-dom';

const ClientDetails_Account = () => {
  const { id } = useParams();
const [account,setaccount]=useState([])

const accountDetails=async()=>{
try{
const account=await TaxAccountService.getAccountDetails(id)
setaccount(account.taxAcoount)
}catch(err){
console.log(err)
}
}

useEffect(()=>{
  accountDetails()
},[])





  return (

    <>

{account!=0 &&
        <Accordion.Item eventKey="6">
    <Accordion.Header>
      <div className='d-flex align-items-center w-50 justify-content-between   ' >
        <span className='fs-6 fw-medium'>Tax account</span>
        <div><button className='btn btn-xxs btn-outline-primary px-4 '> Add </button></div>
      </div>
    </Accordion.Header>
    <Accordion.Body className='' >
   {  account.map((accountDetails)=>(
    <Card className='mb-2'>
        <Card.Body>
          <div className="info-item">
            <span className="label">Account Type</span>
            <span className="value">{accountDetails.taxAccountType}</span>
          </div>
          <div className="info-item">
            <span className="label"></span>
            <span className="value">{accountDetails.account_no}</span>
          </div>
          <div className="info-item">
            <span className="label">PIN</span>
            <span className="value">{accountDetails.account.pin}</span>
          </div>
          <div className="info-item">
            <span className="label">Password</span>
            <span className="value">{accountDetails.account.password}</span>
          </div>
          <div className="info-item">
            <span className="label">Deposit Frequency</span>
            <span className="value">{accountDetails.account.deposit_frequency}</span>
          </div>
        </Card.Body>
        <i className="bi bi-pencil-square edit-icon "></i>
      </Card>

   ))}

    </Accordion.Body>
  </Accordion.Item>
}
  </>
  )
}

export default ClientDetails_Account 