import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, ErrorMessage,Form } from 'formik';
import * as Yup from 'yup';
import { updateClient } from '../../../feature/client/client';
import { useParams } from 'react-router-dom';





const ClientDetailsBusiness = () => {
  const { id } = useParams();
  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    ein: Yup.string().required('Required'),
    formationDate: Yup.date().required('Required'),
    businessStructure: Yup.string().required('Required'),
    businessActivity: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    street: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    zip: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    registeredAgentFirstName: Yup.string().required('Required'),
    registeredAgentMiddleName: Yup.string().required('Required'),
    registeredAgentLastName: Yup.string().required('Required'),
    registeredAgentAddress: Yup.string().required('Required'),
  });

  const businessStructures = ["LLC", "Corporation", "Sole Proprietorship"];
  const businessActivities = ["Retail", "Service", "Manufacturing"];
  const shareholders = ["Shareholder1", "Shareholder2", "Shareholder3"];
  const[clientDetails,setclientDetails]=useState([])
  console.log(clientDetails)
 
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


 //fetch the client details by redux  
var clients = useSelector((state) => state.client.client);
useEffect(() => {


  setclientDetails(clients)
    

}, [clients])

function formatDate(dateString) {

  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();


  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

 
  return `${formattedDay}-${formattedMonth}-${year}`;
}

const dispatch = useDispatch();
const handleUpdate = async (values) => {
  //  values.deletedImages = deletedImages
  //  console.log(values);


  const res = await dispatch(updateClient({id,values})).unwrap();
  console.log(res);
  if (res) {
   alert("update")
  }
};

return (
  <div>
    <div>
      <div className='d-flex align-items-center gap-3'>
        <h5 className='pt-2'>Business Details</h5>
        {/* <div>
          <button
            className='btn btn-md btn-outline-primary px-4'
            onClick={handleShow}
          >
            Edit
          </button>
        </div> */}
      </div>

      <div className="card info-rows mt-4">
        <div className="card-body">
          <div className="row">
            <div className="col-4 info-item">
              <span className="label">EIN:</span><span className="value">{clientDetails.ein}</span>
            </div>
            <div className="col-4 info-item">
              <span className="label">No of Owners:</span><span className="value">3 Owners</span>
            </div>
            <div className="col-4 info-item">
              <span className="label">Date of Information:</span><span className="value">{formatDate(clientDetails.date_of_formation)}</span>
            </div>
            <div className="col-4 info-item">
              <span className="label">Business Activity:</span><span className="value">{clientDetails.address_line_1}</span>
            </div>
            <div className="col-8 info-item">
              <span className="label">Registered Agent:</span>
              <span className="value">{clientDetails.registered_agent_name}<br />{clientDetails.registered_agent_address}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal show={show} onHide={handleClose} className='modal-lg' >
      {/* <Modal.Header closeButton>
        <Modal.Title>Modal heading</Modal.Title>
      </Modal.Header> */}
      <Modal.Body className='modal-lg'>

        <div>

          <Formik
            initialValues={clientDetails}
            enableReinitialize={true}
            // validationSchema={validationSchema}
            onSubmit={(values ,{ setSubmitting }) => {
              handleUpdate(values)
              setSubmitting(false);
              console.log(values);

            }}
          >
            {({ handleChange }) => (
              <Form>
                <div className='form-group'></div>
                <div className="row gap-3 right-content p-3">
                  <div className="col col-md-12 p-4">
                    <div>
                      <h5 className="fw-bold mb-3">Business Details</h5>
                      <div className="row mb-3">
                        <label htmlFor="name">Name</label>
                        <div className="col">
                          <Field
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                          
                          />
                          <ErrorMessage name="name" component="div" />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-sm-6">
                          <label htmlFor="ein">EIN</label>
                          <div>
                            <Field
                              type="text"
                              className="form-control"
                              id="ein"
                              name="ein"
                              
                            />
                            <ErrorMessage name="ein" component="div" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <label htmlFor="formationDate">Date of Formation</label>
                          <div>
                            <Field
                              type="date"
                              className="form-control"
                              id="formationDate"
                              name="formationDate"
                           
                            />
                            <ErrorMessage name="formationDate" component="div" />
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-sm-6">
                          <label htmlFor="businessStructure">Business Structure</label>
                          <div className="dropdown border">
                            <Field
                              as="select"
                              className="form-control"
                              name="businessStructure"
                              onChange={handleChange}
                            >
                              <option value="">Select</option>
                              {businessStructures.map((structure, index) => (
                                <option key={index} value={structure}>{structure}</option>
                              ))}
                            </Field>
                            <ErrorMessage name="businessStructure" component="div" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <label htmlFor="businessActivity">Business Activity</label>
                          <div className="dropdown border">
                            <Field
                              as="select"
                              className="form-control"
                              name="businessActivity"
                              onChange={handleChange}
                            >
                              <option value="">Select</option>
                              {businessActivities.map((activity, index) => (
                                <option key={index} value={activity}>{activity}</option>
                              ))}
                            </Field>
                            <ErrorMessage name="businessActivity" component="div" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col col-md-12 p-4">
                    <div>
                      <h5 className="fw-bold mb-3">Primary Address</h5>
                      <div className="row mb-3">
                        <div className="col-sm-12">
                          <label htmlFor="address_line_1">Address Finder</label>
                          <div>
                            <Field
                              type="text"
                              className="form-control"
                              id="address_line_1"
                              name="address_line_1"
                            />
                            <p>(Type or search address)</p>
                            <ErrorMessage name="address_line_1" component="div" />
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-sm-12">
                          <label htmlFor="address_line_2">Street Name & Number</label>
                          <div>
                            <Field
                              type="text"
                              className="form-control"
                              id="address_line_2"
                              name="address_line_2"
                            
                            />
                            <ErrorMessage name="address_line_2" component="div" />
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-sm-6">
                          <label htmlFor="city">City</label>
                          <div>
                            <Field
                              type="text"
                              className="form-control"
                              id="city"
                              name="city"
                            
                            />
                            <ErrorMessage name="city" component="div" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <label htmlFor="zip">Zip</label>
                          <div>
                            <Field
                              type="text"
                              className="form-control"
                              id="zip"
                              name="zip"
                             
                            />
                            <ErrorMessage name="zip" component="div" />
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-sm-6">
                          <label htmlFor="country">Country</label>
                          <div>
                            <Field
                              type="text"
                              className="form-control"
                              id="country"
                              name="country"
                              
                            />
                            <ErrorMessage name="country" component="div" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <label htmlFor="state">State</label>
                          <div>
                            <Field
                              type="text"
                              className="form-control"
                              id="state"
                              name="state"
                             
                            />
                            <ErrorMessage name="state" component="div" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col col-md-12 p-4">
                    <div>
                      <h5 className="fw-bold mb-3">Registered Agent</h5>
                      <div className="row mb-3">
                        <div className="col-sm-12">
                          <label htmlFor="shareholder">Populate from Shareholders</label>
                          <div className="dropdown border">
                            <Field
                              as="select"
                              className="form-control"
                              name="shareholder"
                              onChange={handleChange}
                            >
                              <option value="">Select</option>
                              {shareholders.map((shareholder, index) => (
                                <option key={index} value={shareholder}>{shareholder}</option>
                              ))}
                            </Field>
                            <ErrorMessage name="shareholder" component="div" />
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-sm-4">
                          <label htmlFor="registered_agent_name">First Name</label>
                          <div>
                            <Field
                              type="text"
                              className="form-control"
                              id="registered_agent_name"
                              name="registered_agent_name"
                              
                            />
                            <ErrorMessage name="registered_agent_name" component="div" />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <label htmlFor="registered_agent_middlename">Middle Name</label>
                          <div>
                            <Field
                              type="text"
                              className="form-control"
                              id="registered_agent_middlename"
                              name="registered_agent_middlename"
                             
                            />
                            <ErrorMessage name="registered_agent_middlename" component="div" />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <label htmlFor="registered_agent_surname">Last Name</label>
                          <div>
                            <Field
                              type="text"
                              className="form-control"
                              id="registered_agent_surname"
                              name="registered_agent_surname"
                              
                            />
                            <ErrorMessage name="registered_agent_surname" component="div" />
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-sm-12">
                          <label htmlFor="registered_agent_address">Registered Agent Address</label>
                          <div>
                            <Field
                              type="text"
                              className="form-control"
                              id="registered_agent_address"
                              name="registered_agent_address"
                              
                            />
                            <ErrorMessage name="registered_agent_address" component="div" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='justify-content-start  d-flex gap-2'>
                    <div>
                    <Button  type='submit' className='me-2' variant="primary" >
              Save
            </Button>
                    </div>
                    <div>
                      <Button variant="outline-primary" onClick={handleClose}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>

        </div>

      </Modal.Body>
      {/* <Modal.Footer className='justify-content-start'>
        <Button variant="primary" onClick={handleClose}>
          save
        </Button>
        <Button variant="outline-primary" onClick={handleClose}>
         Cancel
        </Button>
      </Modal.Footer> */}
    </Modal>



  </div>
);
};

export default ClientDetailsBusiness;
