import axiosInstance from "../utils/axiosInstance";

const API_URL = `${process.env.REACT_APP_API_URL}/api/admin/v1/`;
const getAllClient = async ( data,params ) => {
   
    const res = await axiosInstance.post(API_URL + "clients",data,{params},{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;

}


const getClientById = async (id) => {
   
    const res = await axiosInstance.get(API_URL + "clients/"+id,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;

}

const getClient_portal_User_List = async (client_id,searchQuery,currentPage) => {
    
   
    const res = await axiosInstance.get(API_URL + "users/get_Client_portal_User/"+client_id,{  params: {
        search: searchQuery,
        page:currentPage
    }},{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;

}

const updateClient = async (id,values) => {
   
    const res = await axiosInstance.patch(API_URL + "clients/"+id,values,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;

}

const clientService={
    getAllClient,
    getClientById,
    updateClient,
    getClient_portal_User_List

}

export default clientService












