import React, { Fragment } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import Clients from './pages/Clients/Clients';
import ClientDetails from './pages/Clients/ClientDetails';
import Login from "./pages/Auth/login";
import Chats from "./pages/Chats/Chats";
import Clientportal from "./pages/ClientPortal/Clientportal";
import FileManager from "./pages/filemanager/Filemanager";

import ProtectedRoutes from "./utils/protectedRoutes";
import PublicRoutes from "./utils/publicRoutes";






const App = () => {
  return (
    <Router>
    <Fragment>
      <Routes>
              <Route path="/" element={<ProtectedRoutes/>}>
             <Route path="/clients" element={<Clients/>} />
              <Route path="/client/:id" element={<ClientDetails/>}/>
              <Route path="/Client_portal_user" element={<Clientportal/>}/>
              <Route path="/documents/:id" element={<FileManager/>}/>

              <Route path="/chats" element={<Chats/>}/>
              </Route>

              <Route element={<PublicRoutes/>}>
              <Route path="/login" element={<Login/>} />
              </Route>

            </Routes>
          </Fragment>
      </Router>
  );
};

export default App;
