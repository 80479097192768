import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { trashicon } from "../../../utils/icons";

const Userdetails = ({user,userLastLogin,setstatus,setselectedUserId,setstatusChange,handleTrashClick}) => {

const [loginAttempts,setloginAttempts]=useState([])
// console.log(user)
const [isActive, setIsActive] = useState(user.status);
console.log(isActive)
  
useEffect(()=>{
  const userLogin = userLastLogin.find((item) => item.userId === user.id);

  if (userLogin) {
    setloginAttempts(userLogin.loginAttempts.slice(0, 10)); // Slice to get only the last 10 login attempts
  }
},[])



const handleToggle = async () => {

  setIsActive(!isActive); 
  setstatus(!isActive)
  setselectedUserId(user.id)
  setstatusChange(true)
 
};

  return (
    <div className="wrapper">
 
          <div className="text-start mb-3">
            <div className="row">
              <div className="col">
                <div>
                  <span className="fw-medium ">Full Name</span>
                </div>
                <div>
                  <span  className="fw-bold">{`${user.name} ${user.surname
                    }`}</span>
                </div>
              </div>
              <div className="col ">
                <div>
                  <span >Email</span>
                </div>
                <span>
                  <span  className="fw-bold">
                  {user.email}
                  </span>
                </span>
              </div>
              
              {user.phone && (
              <div className="col">
                <div>
                  <span >MOBILE</span>
                </div>
                <div>
                  <span className="fw-bold"> {user.phone}</span>
                </div>
              </div>
              )}
            </div>
          </div>
          <div className="active-inactive-toggle py-3">
            <span className="text-start fs-xxs">Client Portal Access</span>
            <div className="d-flex gap-2 align-items-center" onClick={handleToggle} >
              <span className={isActive? "text-success fw-medium" : "text-danger fw-medium"}>
                {isActive? 'Active' : 'Inactive'}
              </span>
              <FontAwesomeIcon
                // icon={isActive ? faToggleOn : faToggleOff}
                icon={isActive ? faToggleOn : faToggleOff}

                size="lg"
                color={isActive ? "green" : "red"}
              />
              <small className="text-muted">{isActive ? 'Click to Disable' : 'Click to Enable'}</small>
            </div>
          </div>
          <hr class="border border-1 border-dark" />
       { loginAttempts.length!=0&&  <div>
            <div className="py-3">
              <span className="text-start fw-semibold ">LOGIN ATTEMPTS</span>
            </div>
            <table className="table table-sm table-stripped">
              <thead>
                <tr className="text-start ">
                  <th  scope="col">
                    Date & Time
                  </th>
                  <th className="text-secondary fw-semibold" scope="col">
                   IP Address
                  </th>
                  <th  scope="col">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className=" text-start">
            { loginAttempts.length>0&&loginAttempts.map((loginAttempts)=>(
              <tr>
                  <td>{loginAttempts.loginTime}</td>
                  <td>{loginAttempts.iP_ADDRESS}</td>
                  <td>{loginAttempts.status}</td>
                </tr>
            ))   }

              </tbody>
            </table>
          </div>}
          <div className="py-3">
            <button className="btn btn-outline-danger"  onClick={() => handleTrashClick(user.id)} >
              <FontAwesomeIcon icon={trashicon} /> Delete this user
            </button>
          </div>
      </div>
  );
};

export default Userdetails;
