import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message/message.js";

import dbaService from "../../services/dba.service.js";


// import CategoryService from "../../services/category.service";

let initialState = {
    dbas: [],
    dba: {},
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 1,
    
  };

export const fetchdba = createAsyncThunk(
  "dba/fetchdba",
  async ({id},thunkAPI) => {
   
    try {
      const data = await dbaService.getDba(id);
    //   thunkAPI.dispatch(setMessage(data.message));
   
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const saveDba = createAsyncThunk(
  "Dba/CreateDba",
  async (values,thunkAPI) => {
    try {
     
      const data = await dbaService.SaveDba(values);
    //   thunkAPI.dispatch(setMessage(data.message));
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const EditDba = createAsyncThunk(
    "Dba/EditDba",
    async ({DbaId,finalValue},thunkAPI) => {
      try {
       
        const data = await dbaService.EditDba(DbaId,finalValue);
      //   thunkAPI.dispatch(setMessage(data.message));
        return data;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  );
  
  



const dbaSlice = createSlice({
    name: 'client',
    initialState,
    reducers: { },
    extraReducers(builder) {
       
        // builder.addCase(fetchAllclients.fulfilled, (state, action) => {
        //     //  console.log(action.payload)
        //  state.clients =  action.payload;
        // //  state.limit = action.payload.limit;
        // //  state.page = action.payload.page;
        // //  state.totalPages = action.payload.totalPages;
        // //  state.totalResults = action.payload.totalResults

        // })

        builder.addCase(fetchdba.fulfilled, (state, action) => {
        //  console.log(action.payload)
       state.dba =  action.payload;
      //  state.limit = action.payload.limit;
      //  state.page = action.payload.page;
      //  state.totalPages = action.payload.totalPages;
      //  state.totalResults = action.payload.totalResults

      })

      
    }
})

// export const getAllclients = (state) => state.client.clients;
export const getdba = (state) => state.dba.dba;
export default dbaSlice.reducer
