import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";

const PreviewFileModal = ({ show, handleClose, fileUrl }) => {
  const [fileExists, setFileExists] = useState(true);

  useEffect(() => {
    if (fileUrl) {
      
      axios
        .head(fileUrl)
        .then(() => {
          setFileExists(true); // File exists
        })
        .catch(() => {
          setFileExists(false); // File not found
        });
    }
  }, [fileUrl]);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {fileExists ? (
          <iframe src={fileUrl} width="100%" height="500px" title="File Viewer" />
        ) : (
          <div style={{ textAlign: "center", padding: "20px", color: "red" }}>
            <h5>File not found</h5>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PreviewFileModal;
