import React, { useCallback, useEffect } from 'react'
import { Row, Col, Card, Accordion, } from 'react-bootstrap';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';

import ClientDetails_Banks from './ClientDetails_Banks';
import ClientDetails_Address from './ClientDetails_address';
import ClientDetails_Contact from './ClientDetails_Contact';
import ClientDetails_Login from './ClientDetails_Login';
import { ClientDetails_ShareHolder } from './ClientDetails_ShareHolder';
import ClientDetails_Account from './ClientDetails_Account';
import { useDispatch } from 'react-redux';
import { EditDba, fetchdba, saveDba } from '../../../feature/DBA/dba';
import { useParams } from 'react-router-dom';



const ClientDeatail_DBA = () => {
  const { id } = useParams();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    address_line_1: Yup.string().required('Address is required'),
    address_line_2: Yup.string().required('Street name and number are required'),
    city: Yup.string().required('City is required'),
    zip: Yup.string().required('Zip is required'),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
  });
  const [showAddpopup, setAddpopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [dbaList,setdbaList]=useState([])
// console.log(dbaList.length)
  const[DbaId,setDbaId]=useState("")
 
  const[dbaDetails,setdbaDetails]=useState([])
  const handleClose = () => setAddpopup(false);
  const handleShow = () => setAddpopup(true);
  const editHandleClose = () => setShowEditPopup(false);
  const editHandleShow = (id,dbaDetails) => {
    setDbaId(id)
  
    setdbaDetails(dbaDetails)
    setShowEditPopup(true)
  
  
  
  };

  const dispatch = useDispatch();

  const CustomSubmitButton = ({ children }) => {
    const { submitForm } = useFormikContext();
    return (
      <Button type="button" className="btn btn-primary" onClick={submitForm}>
        {children}
      </Button>
    );
  };


  const fetchDba = useCallback(async () => {
    try {
   
      const dba =  await dispatch(
        fetchdba({ 
         id
        })
      ).unwrap();

      setdbaList(dba)
      // console.log(dba)

    } catch (err) {
      console.log(err);
    }
  }, []);

useEffect(()=>{
  fetchDba()
},[])



const handleSubmit = async (values) => {

  const res = await dispatch(saveDba(values)).unwrap();
  alert("save")
 console.log(res)
};


const handleUpdate = async (finalValue) => {
  //  values.deletedImages = deletedImages
  //  console.log(values);


  const res = await dispatch(EditDba({DbaId,finalValue})).unwrap();
  console.log(res);
  if (res) {
   alert("update")
  }
};



  return (
    <>
      <div>
       { dbaList.length!=0 && <h5 className='mb-3'>More Info</h5>}
     { dbaList.length!=0 &&  <Accordion defaultActiveKey="0" className="moreinfo_accordion" >
          <Accordion.Item eventKey="1" >
            <Accordion.Header  >
              <div className='d-flex align-items-center w-50 justify-content-between   ' >
                <span className='fs-6 fw-medium'>DBAs</span>
                <div><button className='btn btn-xxs btn-outline-primary px-4 ' onClick={handleShow} > Add </button></div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                {dbaList.map((dbaDetails)=>(

                <Col xs={4} md={6}>
                  <Card className='p-3' >
                    <Card.Body>
                      <Card.Title className='mb-2'>{dbaDetails.name}</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">{dbaDetails.address_line_1}</Card.Subtitle>
                    </Card.Body>
                    <i className="bi bi-pencil-square edit-icon" onClick={()=>editHandleShow(dbaDetails.id,dbaDetails)}></i>
                  </Card>
                </Col>
                
                
                ))}
          
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <ClientDetails_Banks />
          <ClientDetails_Address />
          <ClientDetails_Contact />
          <ClientDetails_Login />
          <ClientDetails_ShareHolder />
          <ClientDetails_Account />
        </Accordion>}
      </div>

      {/* DBAS on click add form */}
      {/* <Modal show={showAddpopup}  className='modal-lg'>
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>Add New DBA</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col col-md-12 p-4">
            <Formik
              initialValues={{
                name: '',
                address_line_1: 'Enter a location',
                address_line_2: '13760 NORTH',
                city: 'HOUSTON',
                zip: '77067',
                country: 'Houston',
                state: '51'
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                console.log(values);
                setSubmitting(false);
                handleClose(); // Close modal after submission
              }}
              
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="form-group">
                    <div className="row mb-3">
                      <div className="col-sm-6">
                        <label htmlFor="name">Name</label>
                        <div>
                          <Field type="text" name="name" className="form-control" />
                          <ErrorMessage name="name" component="div" className="text-danger" />
                        </div>
                      </div>
                    </div>
                    <span className='fs-5 ffw-medium'>Property Address</span>
                    <hr />
                    <div className="row mb-3">
                      <div className="col-sm-12">
                        <label htmlFor="address_line_1">Address</label>
                        <div>
                          <Field type="text" name="address_line_1" className="form-control" />
                          <ErrorMessage name="address_line_1" component="div" className="text-danger" />
                        </div>
                        <p>(Type of search address)</p>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-12">
                        <label htmlFor="address_line_2">Street Name & Number</label>
                        <div>
                          <Field type="text" name="address_line_2" className="form-control" />
                          <ErrorMessage name="address_line_2" component="div" className="text-danger" />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-6">
                        <label htmlFor="city">City</label>
                        <div>
                          <Field type="text" name="city" className="form-control" />
                          <ErrorMessage name="city" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="zip">Zip</label>
                        <div>
                          <Field type="text" name="zip" className="form-control" />
                          <ErrorMessage name="zip" component="div" className="text-danger" />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-6">
                        <label htmlFor="country">Country</label>
                        <div>
                          <Field type="text" name="country" className="form-control" required />
                          <ErrorMessage name="country" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="state">State</label>
                        <div>
                          <Field type="text" name="state" className="form-control" />
                          <ErrorMessage name="state" component="div" className="text-danger" />
                        </div>
                      </div>
                    </div>
                  </div>

                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
        <Modal.Footer className='justify-content-start'>
          <Button type="submit" className="btn btn-primary" onClick={handleClose}>
            Submit
          </Button>
          <Button variant="outline-primary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal show={showAddpopup} className="modal-lg">
      <Modal.Header closeButton onClick={handleClose}>
        <Modal.Title>Add New DBA</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col col-md-12 p-4">
          <Formik
            initialValues={{
              name: '',
              address_line_1: '',
              address_line_2: '',
              city: '',
              zip: '',
              country: '',
              state: '',
              client_id:id
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
            const finalValue={...values,client_id:id}
              handleSubmit(finalValue)
              
             console.log(finalValue)
               
            }}
          >
            {({values, errors, setFieldValue }) => (
              <Form>
                <div className="form-group">
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <label htmlFor="name">Name</label>
                      <div>
                        <Field type="text" name="name"  className="form-control"   />
                        <ErrorMessage name="name" component="div" className="text-danger" />
                      </div>
                    </div>
                  </div>
                  <span className="fs-5 ffw-medium">Property Address</span>
                  <hr />
                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <label htmlFor="address_line_1">Address</label>
                      <div>
                        <Field type="text" name="address_line_1" className="form-control" />
                        <ErrorMessage name="address_line_1" component="div" className="text-danger" />
                      </div>
                      <p>(Type of search address)</p>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <label htmlFor="address_line_2">Street Name & Number</label>
                      <div>
                        <Field type="text" name="address_line_2" className="form-control" />
                        <ErrorMessage name="address_line_2" component="div" className="text-danger" />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <label htmlFor="city">City</label>
                      <div>
                        <Field type="text" name="city" className="form-control" />
                        <ErrorMessage name="city" component="div" className="text-danger" />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="zip">Zip</label>
                      <div>
                        <Field type="text" name="zip" className="form-control" />
                        <ErrorMessage name="zip" component="div" className="text-danger" />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <label htmlFor="country">Country</label>
                      <div>
                        <Field type="text" name="country" className="form-control" required />
                        <ErrorMessage name="country" component="div" className="text-danger" />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="state">State</label>
                      <div>
                        <Field type="text" name="state" className="form-control" />
                        <ErrorMessage name="state" component="div" className="text-danger" />
                      </div>
                    </div>
                  </div>
                </div>
                <Modal.Footer className="justify-content-start">
                <Button type="submit" className="btn btn-primary" >
            Submit
          </Button>
                  <Button variant="outline-primary" onClick={handleClose}>
                    Cancel
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>


      {/* DBAS on click edit form */}
      <Modal show={showEditPopup} onHide={editHandleClose} className='modal-lg'>
        <Modal.Header closeButton>
          <Modal.Title>Add New DBA</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={dbaDetails}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                const finalValue={...values,client_id:id}
                console.log(values);
                setSubmitting(false);
                handleUpdate(finalValue)
            
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="form-group">
                    <div className="">
                      <label htmlFor="name">Name</label>
                      <div>
                        <Field type="text" name="name" className="form-control" />
                        <ErrorMessage name="name" component="div" className="text-danger" />
                      </div>
                    </div>

                    <span className='fs-5 fw-medium'>Property Address</span>
                    <hr />

                    <div className="">
                      <label htmlFor="address">Address</label>
                      <div>
                        <Field type="text" name="address_line_1" className="form-control" />
                        <ErrorMessage name="address" component="div" className="text-danger" />
                      </div>
                      <p>(Type of search address)</p>
                    </div>

                    <div className="">
                      <label htmlFor="street">Street Name & Number</label>
                      <div>
                        <Field type="text" name="address_line_2" className="form-control" />
                        <ErrorMessage name="street" component="div" className="text-danger" />
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-sm-6">
                        <label htmlFor="city">City</label>
                        <div>
                          <Field type="text" name="city" className="form-control" />
                          <ErrorMessage name="city" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="zip">Zip</label>
                        <div>
                          <Field type="text" name="zip" className="form-control" />
                          <ErrorMessage name="zip" component="div" className="text-danger" />
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-sm-6">
                        <label htmlFor="country">Country</label>
                        <div>
                          <Field type="text" name="country" className="form-control" />
                          <ErrorMessage name="country" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="state">State</label>
                        <div>
                          <Field type="text" name="state" className="form-control" />
                          <ErrorMessage name="state" component="div" className="text-danger" />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className='col-md-12 justify-content-start' >
                      <i class="bi bi-toggle-on" style={{backgroundColor:"purple"}}></i>
                      </div>
                    </div>



                  </div>
                  <Modal.Footer className='justify-content-between'>
          <div>
            <Button type='submit' className='me-2' variant="primary" >
              Save
            </Button>
            <Button variant="outline-primary" onClick={editHandleClose}>
              Cancel
            </Button>
          </div>
          <div>
            <Button variant="outline-danger" onClick={editHandleClose}>
              Delete
            </Button>
          </div>
        </Modal.Footer>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
     
      </Modal>
    </>





  )
}

export default ClientDeatail_DBA


{/* <Form>
            <div className=" col col-md-12  p-4  " >
              <div className="">
                <form>
                  <dir className="form-group">
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <label for="">Name</label>
                      <div>
                        <input
                          type="name"
                          className="form-control"
                          id="ein"
                          value=""
                        />
                      </div>
                    </div>
                  </div>
                  <span className='fs-5 ffw-medium'>Property Address</span>
                  <hr />
                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <label for="">Address</label>
                      <div>
                        <input
                          type="name"
                          className="form-control"
                          id="ein"
                          value="Enter a loction"
                        />
                      </div>
                      <p>(Type of search address)</p>
                    </div>

                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <label for="">Street Name & Number</label>
                      <div>
                        <input
                          type="name"
                          className="form-control"
                          id="ein"
                          value="13760 NORTH"

                        />
                      </div>
                    </div>

                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <label for="">City</label>
                      <div>
                        <input
                          type="name"
                          className="form-control"
                          id="ein"
                          value="HOUSTON"

                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <label for="">Zip</label>
                      <div>
                        <input
                          type="name"
                          className="form-control"
                          id="ein"
                          value="77067"

                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <label for="">Country</label>
                      <div>
                        <input
                          type="name"
                          className="form-control"
                          id="ein"
                          value="Houston"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <label for="">State</label>
                      <div>
                        <input
                          type="name"
                          className="form-control"
                          id="ein"
                          value="51"

                        />
                      </div>
                    </div>
                  </div>

                  </dir>

                </form>
              </div>
            </div>
          </Form> */}