import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setMessage } from "../message/message.js";
import { setLoading } from "../loading/loading.js";
import message from "../../utils/message.js";
import chatsService from "../../services/chats.service";



export const fetchAllThreads = createAsyncThunk(
  "Thread/fetchAllThreads",
  async ({data},thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    thunkAPI.dispatch(setMessage(message.loading))
    try {
      const threads = await chatsService.getAllThreads(data) ;
     
      return threads;
    } catch (error) {
  
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message)||
        error.message ||
        error.toString();
  //  console.log(message)
  thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue();
    }finally {
      thunkAPI.dispatch(setLoading(false)); 
    thunkAPI.dispatch(setMessage(""))

    }
  }
);


export const fetchThreadsById = createAsyncThunk(
  "Thread/fetchThreadsByID",
  async ({ThreadsId},thunkAPI) => {
   
    try {
      const thread = await chatsService.getThreadsByid(ThreadsId) ;
    //   thunkAPI.dispatch(setMessage(data.message));
      return thread;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      
   console.log(message)
      return thunkAPI.rejectWithValue();
    }
  }
);





const chatSlice = createSlice({
    name: 'threads',
    initialState:{
threads:[],
thread:[]
    },
    reducers:{},
    extraReducers(builder) {
       
 

        builder.addCase(fetchAllThreads.fulfilled, (state, action) => {
          state.threads =  action.payload;
})


builder.addCase(fetchThreadsById.fulfilled, (state, action) => {
  console.log(action.payload)
  state.thread = action.payload;
})

 }
})


export default chatSlice.reducer