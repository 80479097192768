import axiosInstance from "../utils/axiosInstance";


const API_URL = `${process.env.REACT_APP_API_URL}/api/admin/v1/folder/`;


const AddFileToFolder=async(data)=>{
    const res = await axiosInstance.post(API_URL,data,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;
}


const FolderList=async(client_id,search,currentPage)=>{
    const res = await axiosInstance.get(API_URL,{params: {
        client_id: client_id,
        search:search,
        page:currentPage
    }},{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;
}



const EditFileToFolder=async(folder_id,data)=>{
    const res = await axiosInstance.post(API_URL+`${folder_id}/addFileToFolder`,data,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;
}



const DeleteFileToFolder=async(folder_id,data)=>{
    const res = await axiosInstance.post(API_URL+`${folder_id}/deleteFileToFolder`,data,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;
}



const DeleteFolder=async(folder_id)=>{
    const res = await axiosInstance.delete(API_URL,{params: {
        folderId: folder_id,
        
    }},{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;
}


const EditFolder=async(folder_id,data)=>{
    const res = await axiosInstance.post(API_URL+`${folder_id}/getEditFiles`,data,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;  
}





const FolderService={AddFileToFolder,FolderList,EditFileToFolder,DeleteFolder,EditFolder,DeleteFileToFolder}

export default FolderService