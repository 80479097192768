import axiosInstance from "../utils/axiosInstance";


const API_URL = `${process.env.REACT_APP_API_URL}/api/admin/v1/files/`;

const uploadFile = async ( data) => {
   
    const res = await axiosInstance.post(API_URL+"upload",data,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },})

    return res.data;

}


const saveFile=async(data)=>{
    const res = await axiosInstance.post(API_URL+"save",data,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;
}




const getFile=async(data)=>{
    const res = await axiosInstance.post(API_URL,data,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;
}

const TrashedFile=async(data)=>{
    const res = await axiosInstance.post(API_URL+"getTrash", data,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;
}


const DeleteFile=async(id)=>{
    const res = await axiosInstance.delete(API_URL+`${id}/delete`,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;
}

const RecoverFile=async(id)=>{
    const res = await axiosInstance.patch(API_URL+`${id}/recover`,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;
}
const UpdateVisible=async(id,data)=>{
    const res = await axiosInstance.patch(API_URL+`${id}/updateVisible`,data,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;
}


const AddFileToFolder=async(id,data)=>{
    const res = await axiosInstance.post(API_URL+`${id}/addFileToFolder`,data,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;
}



const FileManagerService={
    uploadFile,getFile,TrashedFile,DeleteFile,RecoverFile,UpdateVisible,AddFileToFolder,saveFile


}




export default FileManagerService


