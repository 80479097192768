import React from 'react';
import { Form } from 'react-bootstrap';
import { formatDateTime } from '../../utils/dateTimeFormat';


const TaskList = ({ tasks, threadId, showTasks }) => {
    return (
        <div className={`tasks ${showTasks? 'expanded' : 'collapsed'} `}>
            <div className='container-fluid'>
            <h2 className="tasks__header"> <span className='tasks__header-title'>Your Tasks</span></h2>

            <div className='tasks__list'>
                <Form>
             {tasks && tasks.map((task, index) => (
                <Form.Check
                  key={index}
                  type="checkbox"
                  id={`task-${index}`}
                  label={`${task.taskDetails} ${task.taskDone ? `(${formatDateTime(task.taskDoneTime)})` : ''}`}
                  className="text-black fw-semibold"
                  checked={task.taskDone}
                 
                />
              ))}
                </Form>
            </div>
        </div>
    </div >
  );
};

export default TaskList;
