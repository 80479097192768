import React from 'react';
import Cookies from 'js-cookie';
import { Outlet, Navigate } from 'react-router-dom';

const getAccessToken = () => {
  // Get the 'admone' cookie which contains both tokens
  const admoneCookie = Cookies.get('admone');
  if (admoneCookie) {
    // Parse the cookie to extract the tokens object
    const tokens = JSON.parse(admoneCookie);
    return tokens.accessToken;
  }
  return null;
};

const PublicRoutes = () => {
  const token = getAccessToken();
  const isAuthenticated = !!token;

  if (isAuthenticated) {
    // Redirect to '/clients' if authenticated
    return <Navigate to="/clients" />;
  }

  // Render the child routes if not authenticated
  return <Outlet />;
};

export default PublicRoutes;
