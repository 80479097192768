import React, { useEffect, useState } from "react";
import clientService from "../../../services/client.service";
import Select from 'react-select';
import { getUserIdFromToken } from "../../../utils/decodeToken";
import Error from "../../../components/Error";
import { validateComposeChatFields, validateNewClientPoratlUser } from "../../../utils/validation";
import { useSelector } from "react-redux";
import InputMask from 'react-input-mask';

const AddnewUser = ({ shareHolderDetails, contactDetails, createUser, createUserError, setShow, IsEdit, Details, updateUser, UpdateUserError }) => {
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedUser, setSelectedUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    client_ids: []
  });



  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    clients: ""
  });


  const loadingmessage = useSelector((state) => state.message.message);
  const isLoading = useSelector((state) => state.loading.loading);

  // console.log(Details)
  useEffect(() => {

    if (IsEdit) {
      setSelectedUser({
        firstName: Details.user_details.name,
        lastName: Details.user_details.surname,
        email: Details.user_details.email,
        mobile: Details.user_details.phone,

      });
    }
  }, [IsEdit])
  const handleSelection = (user, type) => {
    setSelectedUser({
      firstName: type === "shareholder" ? user.name : user.name.split(" ")[0],
      lastName: type === "shareholder" ? "" : user.surname,
      email: user.email || "",
      mobile: user.phone || "",
    });

  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setSelectedUser((prev) => ({
      ...prev,
      [id]: value,
    }));
  };
  const isSaveDisabled = !selectedUser.firstName || !selectedUser.email;



  const [clientList, setclientList] = useState([])

  const [userId, setuserId] = useState("")



  const fetchClientUser = async (searchKeyword) => {
    try {
      const data = {
        search_keyword: searchKeyword,
        user_id: userId
      }
      const user = await clientService.getAllClient(data)
      setclientList(user.data.sortedClients)
      console.log(user)
    } catch (err) {
      console.log(err)
    }
  }

  const handleInputChangeclient = (inputValue) => {
    if (inputValue.length > 2) {
      fetchClientUser(inputValue);
    }
  };

  // Function to handle selection change
  const handleChange = (selectedOptions) => {
    setSelectedClients(selectedOptions.map(option => option.value));
    setSelectedUser(prevState => ({
      ...prevState,
      client_ids: selectedOptions.map(option => option.value)
    }));

    if (IsEdit) {

      updateUser(Details.user_details.id, selectedOptions.map(option => option.value))
    }
  };

  // Map client list to the format needed by react-select
  const options = clientList && clientList.map(client => ({
    value: client.id,
    label: client.name,
  }));



  useEffect(() => {
    const userId = getUserIdFromToken();
    setuserId(userId)


  }, [userId])



  const handleSave = () => {
    const validationErrors = validateNewClientPoratlUser(selectedUser);

    if (Object.keys(validationErrors).length === 0) {
     
      createUser(selectedUser)
      
    } else {
      setErrors(validationErrors);
    }
  }



  return (
    <div className="wrapper">


      <div className="pb-4">

        <div className="row  mb-3 ">
          <div className="col-6">
            <label htmlFor="firstName" className="form-label ">
              First Name<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              placeholder=""
              disabled={IsEdit}
              value={selectedUser.firstName}
              onChange={handleInputChange}

            />
            {errors.firstName && <Error error={errors.firstName} />}


          </div>
          <div className="col-6">
            <label htmlFor="lastName" className="form-label ">
              Last Name<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control "
              id="lastName"
              placeholder=""
              disabled={IsEdit}
              value={selectedUser.lastName}
              onChange={handleInputChange}

            />
            {errors.lastName && <Error error={errors.lastName} />}
          </div>

        </div>
        <div className="row   mb-3">

          <div className="col-6">
            <label htmlFor="email" className="form-label ">
              Email Address<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="email"
              placeholder=""
              disabled={IsEdit}
              value={selectedUser.email}
              onChange={handleInputChange}

            />
            {errors.email && <Error error={errors.email} />}
          </div>
          <div className="col-6">
            <label htmlFor="mobile" className="form-label ">
              Mobile
            </label>
            {/* <input
              type="text"
              className="form-control "
              id="mobile"
              placeholder=""
              disabled={IsEdit}
              value={selectedUser.mobile}
              onChange={handleInputChange}

            /> */}

            <InputMask
              mask="(999) 999-9999"   // Mask for American phone number format
              className="form-control"
              id="mobile"
              placeholder=""
              disabled={IsEdit}
              value={selectedUser.mobile}
              onChange={handleInputChange}
            />
            {errors.mobile && <Error error={errors.mobile} />}
          </div>
        </div>
        <hr/>
        <div className="row   mb-3">
          <div className="col-12 col-sm-12">
            <label htmlFor="clients" className="form-label ">
              Associated Clients <span className="text-danger">*</span>
            </label>
            {Details && Details.clients.length > 0 && (
              <ol>
                {Details.clients.map((client, index) => (
                  <li key={index} className="my-1" style={{fontSize:"0.9rem", fontWeight:"bold",}}>
                    {client.name}
                  </li>
                ))}
              </ol>
            )}
            <Select
              isMulti
              options={options}
              onInputChange={handleInputChangeclient}
              onChange={handleChange}
              placeholder="Start typing the client name to search..."
            />
            {errors.clients && <Error error={errors.clients} />}
          </div>
        </div>
        <hr/>
      </div>


      {UpdateUserError && (<Error error={UpdateUserError} />)}
      {createUserError && (<Error error={createUserError} />)}

      <div className="btn-wrapper pb-4">
        <div className="d-flex justify-content-end gap-3 ">

          <a href="javascript:void(0)" className="btn btn-md btn-outline-primary" onClick={() => setShow(false)} >
            Cancel
          </a>

          {!IsEdit &&

            <a href="javascript:void(0)" className="btn btn-md btn-primary" onClick={handleSave} >
             
             {isLoading ? (loadingmessage) : ("Create User")}
            </a>
          }

        </div>
      </div>

    </div>
  );
};

export default AddnewUser;
