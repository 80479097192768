import axios from 'axios';
const API_URL = `${process.env.REACT_APP_API_URL}/admin/api/clients/`;
const getaddressDetails= async (id ) => {

    const res = await axios.get(API_URL + "address/"+id)

    return res.data;

}



const getaddressDetailsService = {
 
    getaddressDetails
   
  }
  
  export default getaddressDetailsService;