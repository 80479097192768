import React, { useEffect, useState } from 'react'
import {  Card, Accordion, } from 'react-bootstrap';
import contactService from '../../../services/contact.service';
import { useParams } from 'react-router-dom';

const ClientDetails_Contact = () => {
  const { id } = useParams();
const [Contacts,setContacts]=useState([])

const getConatctDetails=async()=>{
  try{

const Contacts=await contactService.getContactDetails(id)
setContacts(Contacts.Contact)
// console.log(Contacts)
  }catch(err){
    console.log(err)

  }
}
useEffect(()=>{
  getConatctDetails()
},[])

  return (
    <>


     { Contacts!=0&&<Accordion.Item eventKey="4">
    <Accordion.Header>
      <div className='d-flex align-items-center w-50 justify-content-between   ' >
        <span className='fs-6 fw-medium'>Contacts</span>
        <div><button className='btn btn-xxs btn-outline-primary px-4 '> Add </button></div>
      </div>
    </Accordion.Header>
    <Accordion.Body className='' >
    { Contacts.map((contactsDetails)=>(
      <Card>
        <Card.Body>
          <div className="info-item">
            <span className="label">CONTACT NAME</span>
            <span className="value"> {`${contactsDetails.name} ${contactsDetails.surname}`}</span>
          </div>
          <div className="info-item">
            <span className="label">DESIGNATION</span>
            <span className="value"> {contactsDetails.designation}</span>
          </div>
          <div className="info-item">
            <span className="label">EMAIL ADDRESS</span>
            <span className="value">{contactsDetails.email}</span>
          </div>
          <div className="info-item">
            <span className="label">PHONE</span>
            <span className="value"> {contactsDetails.phone}</span>
          </div>
        </Card.Body>
        <i className="bi bi-pencil-square edit-icon "></i>
      </Card> 
    )) }
    </Accordion.Body>
  </Accordion.Item>
}
  </>
  )
}

export default ClientDetails_Contact