import axios from 'axios';
const API_URL = `${process.env.REACT_APP_API_URL}/admin/api/clients/`;
const getloginsDetails= async (id ) => {

    const res = await axios.get(API_URL + "logins/"+id)

    return res.data;

}



const loginsService = {
 
    getloginsDetails
   
  }
  
  export default loginsService;