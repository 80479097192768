import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import clientService from '../../services/client.service';
import Select from 'react-select';

import { getUserIdFromToken } from '../../utils/decodeToken';
import { validateComposeChatFields, validateFiles } from '../../utils/validation';
import Error from '../../components/Error';
import { useDispatch, useSelector } from 'react-redux';
import FileManagerService from '../../services/FileManager.service';
import { setLoading } from '../../feature/loading/loading';
import { setMessage } from '../../feature/message/message';
import message from '../../utils/message';


const NewChatModal = ({ show,tasks,setSelectedUserId,setSelectedClientId, handleClose, messageChange, subjectChange, sendMessage,taskChange,removeTaskField,addTaskField,showComposeError,subject,newmessage }) => {

  const loadingmessage= useSelector((state) => state.message.message);
  const isLoading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
    const [clientPortalUsers, setClientPortalUsers] = useState([]);
   
    const [selectedClient, setSelectedClient] = useState(null);

    const [clientList,setclientList]=useState([])
    const [selectedUsers, setSelectedUsers] = useState([]);
    const[currentPage,setcurrentPage]=useState("1")
    const[userId,setuserId]=useState("")
    const [uploading, setUploading] = useState(false);
    const [uploadIndex, setUploadIndex] = useState(0);
    const [fileuploadingError, setfileuploadingError] = useState(null);
    const [fileuploadError,setfileuploadError]=useState("")

const [searchQuery, setSearchQuery] = useState('');
const [files, setFiles] = useState([]);
const [errors, setErrors] = useState({
    clientError: '',
    userError: '',
    subjectError: '',
    messageError: '',
  });


  useEffect(() => {
    if (errors.clientError || errors.userError || errors.subjectError || errors.messageError) {
      const timeout = setTimeout(() => {
        setErrors({
          clientError: '',
          userError: '',
          subjectError: '',
          messageError: '',
        });
      }, 2000); // Clear errors after 5 seconds
  
      return () => clearTimeout(timeout); // Cleanup the timeout if errors change before 5 seconds
    }
  }, [errors]);

    const fetchClientUser=async(searchKeyword)=>{
        try{
          const data={
            search_keyword:searchKeyword,
            user_id:userId
          }
    const user=  await clientService.getAllClient(data)
    setclientList(user.data.sortedClients)
    console.log(user)
        }catch(err){
         console.log(err)
        }
    }
    
    
    const fetchClient_portal_user=async(clientID)=>{
      try{
      
    const Client_portal_user=  await clientService.getClient_portal_User_List(clientID,searchQuery,currentPage)
    setClientPortalUsers(Client_portal_user.userDetailsList); 
    console.log(Client_portal_user)
      }catch(err){
       console.log(err)
      }
    }
    const handleClientSearch = (inputValue) => {

        fetchClientUser(inputValue);
    };
    
    const handleClientChange = (selectedOption) => {
        setSelectedClient(selectedOption);
       fetchClient_portal_user(selectedOption.value);
       setSelectedClientId(selectedOption.value)
      
    };
    
    const handleUserChange = (selectedOptions) => {
    
    
        const newSelectedValues = selectedOptions.map(option => option.value);
        setSelectedUsers(newSelectedValues);
        // const selectedUserString = newSelectedValues.join(',');
        setSelectedUserId(newSelectedValues)
        // console.log(newSelectedValues)
    };
    
    const clientOptions =clientList&&clientList.map(client => ({
        label: client.name,
        value: client.id
    }));
    
    
    const userOptions = clientPortalUsers.map(user => ({
        label: `${user.name} ${user.surname}`,
        value: user.id,
      
        
    }));
    // console.log(subject)
    // console.log(message)

    // const isSaveButtonDisabled = !(selectedClient && selectedUsers.length > 0) || subject=="" || message=="" ;

   
    useEffect(()=>{
 const userId = getUserIdFromToken();
      setuserId(userId)
        fetchClientUser()
        setSelectedUsers([]);
        fetchClient_portal_user()
    },[])

    let fileDetailsArray = [];

    const saveFile=async(fileDetails)=>{

 
    
      const data={
        clientId: selectedClient.value,
        files: fileDetails,
      }
      
              try{
            
                var response = await FileManagerService.saveFile(data);
                // uploadFilehandleClose()
             
              }catch(err){
            console.log(err)
              }
    
    
              return response
            }
    
    
      const handleFileUpload = async (file, clientId) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('clientId', clientId);
    
    try {
       
          setUploading(true);
    
     const Filesdetails= await FileManagerService.uploadFile(formData);
             
  
    fileDetailsArray.push({
      originalFileName: Filesdetails.originalFileName,
      uniqueFileName: Filesdetails.uniqueFileName,
      visibility: 1
    });
    
    
    
          setUploadIndex((prevIndex) => prevIndex + 1); 
          setUploading(false);
        } catch (error) {
          console.error('Error uploading file:', error);
          setfileuploadingError(error.response.data.errors[0].msg)
          setUploading(false);
        }
      };
    


    const handleSendMessage = async() => {
      try{
        dispatch(setLoading(true));
        dispatch(setMessage(message.loading));
    
        const validationErrors = validateComposeChatFields({ selectedClient, selectedUsers, subject, newmessage });
      
        setErrors(validationErrors);
      
     
        if (!validationErrors.clientError && !validationErrors.userError && !validationErrors.subjectError && !validationErrors.messageError) {
       if (uploadIndex < files.length  && !uploading&& !fileuploadingError) {
            for (let i = 0; i < files.length; i++) {
             await handleFileUpload(files[i], selectedClient.value);  
              }
            }
        
           
            let fileIds = [];
            if(fileDetailsArray.length>0){
            var saveFiles= await saveFile(fileDetailsArray)
        
        fileIds = saveFiles.dbEntries.map((entry) => entry.id);
  
         
          }





          sendMessage(fileIds);
          setSelectedClient(null)
          setSelectedUsers([])
          setfileuploadError("")
        }


      }catch(err){
        console.log(err)
      } finally {
        dispatch(setLoading(false));
  
      }


      };
      

    const handleCloseModal=()=>{
        setSelectedClient(null)
        setSelectedUsers([])
        handleClose()
    }


    const handleFileSelect = (event) => {
      const selectedFiles = event.target.files;
      
      // Validate the selected files
      const validationErrors = validateFiles(selectedFiles);
    
      if (validationErrors.length > 0) {
        validationErrors.forEach((error) => {
          console.error(`Error with file "${error.fileName}": ${error.reason}`);
        });
    
        
        setfileuploadError(`Error: ${validationErrors.map(error => `${error.fileName}: ${error.reason}`).join(', ')}`)
        
        
      }
    
      // If no validation errors, proceed with adding the files to the state
      setFiles([...files, ...selectedFiles]);
    };


  return (
    <Modal className='modal-lg' show={show} onHide={handleClose} animation={true}>
    <Modal.Header closeButton>
        <Modal.Title>Compose</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <form>
            <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label text-dark fw-semibold fs-6">CONTACT</label>
   


<div>
<Select
    onInputChange={handleClientSearch}
    onChange={handleClientChange}
    options={clientOptions}
    placeholder="Search Client"
/>
{selectedClient && (
    <Select
        isMulti
        onChange={handleUserChange}
        options={userOptions}
        placeholder="Select Users"
    />
)}
</div>



                <div id="emailHelp" className="form-text">
                    We'll never share your email with anyone else.
                </div>
                {errors.clientError &&<Error error={errors.clientError}/>}
                {errors.userError && <Error error={errors.userError}/>}
            </div>
            <div className="mb-3">
                <label htmlFor="text-subject" className="form-label text-dark fw-semibold fs-6 ">SUBJECT</label>
                <input
                    type="text"
                    className="form-control"
                    id="text-subject"
                    placeholder="Search Contact / Client"
                    onChange={subjectChange}
                />
                 {errors.subjectError &&<Error error={errors.subjectError}/>}
            </div>
            <label htmlFor="exampleInputPassword1" className="form-label text-dark fw-semibold fs-6 ">MESSAGE</label>
            <div className="form-floating">
                <textarea
                    className="form-control"
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    style={{ height: '100px' }}
                    onChange={messageChange}
                ></textarea>
                <label htmlFor="floatingTextarea2">Type Message here...</label>
                {errors.messageError && <Error error={errors.messageError}/>}
            </div>
            <div className="row mt-3">
                <div className="col">
                    <label htmlFor="Task" className="form-label text-dark fw-semibold fs-6 ">Add Tasks</label>
               
                    {tasks.map((task, index) => (
                        <div key={index} className="d-flex align-items-center mb-2">
    <input
      key={index}
      type="text"
      className="form-control mb-2"
      id={`task-${index}`}
      placeholder="Task details"
      value={task.taskDetails}
      onChange={(event) => taskChange(index, event)}
    />
  <span
              className="ms-2 text-danger"
              style={{ cursor: 'pointer', fontWeight: 'bold' }}
              onClick={() => removeTaskField(index)}
            >
              ×
            </span>
</div>
    
  ))}
                    <div className='d-flex gap-1 mt-3 align-items-center'  onClick={addTaskField}>+<span>add more tasks</span></div>
                </div>

            </div>
                <input type="file" id="attachFiles" name="Browse Files" className="form-control mb-3" multiple  onChange={handleFileSelect} />
                {fileuploadError && <Error error={fileuploadError}/>}
        </form>


        {showComposeError&&(<Error error={showComposeError}/>)}
    </Modal.Body>

    <Modal.Footer className='justify-content-start'>
        {/* <Button variant="primary" onClick={handleSendMessage}  >
            save
        </Button> */}

        <Button variant="primary" onClick={handleSendMessage} disabled={isLoading}  >
        {isLoading?(loadingmessage):("save")}
        </Button>
        <Button variant="outline-primary" onClick={handleCloseModal}>
            Cancel
        </Button>
    </Modal.Footer>
</Modal>
  );
};

export default NewChatModal;
