import axiosInstance from "../utils/axiosInstance";

const API_URL = `${process.env.REACT_APP_API_URL}/api/admin/v1/chat/`;
const getAllThreads = async (data) => {

const res = await axiosInstance.post(API_URL + "threads",data,{
        headers: {
          'Content-Type': 'application/json',
        },
    })

    return res.data;

}

const getMessage=async(Threadid,data)=>{
    const res = await axiosInstance.get(API_URL + `/thread/${Threadid}/getChats`,data,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;  
}






const SendNewMessage = async (Threadid,data) => {
   
    const res = await axiosInstance.post(API_URL + `/thread/${Threadid}/sendNewMessage`,data,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;

}



const createNewThread = async (data) => {
   
    const res = await axiosInstance.post(API_URL +`/createNewThread`,data,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;

}

const chatsService={
    getAllThreads,SendNewMessage,getMessage,createNewThread
 

}

export default chatsService
