import React from 'react';
import { useSelector } from 'react-redux';

const Loading = () => {
 const message= useSelector((state) => state.message.message);
 const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000
};
  return (
 
      <div className="loading-message" >
       {message}
      </div>

      //       <div style={overlayStyle}>
      //  {message}
      // </div>
  
  );
};

export default Loading;
