
import React, { useCallback, useEffect, useState } from 'react'
import { Container, Row, Col, Card, Button, Accordion, } from 'react-bootstrap';
import TaskDataTableComponent from './components/ClientDetails_Tasks';
import ChatsDataTableComponent from './components/ClientDetails_chats';
import Layout from "../../components/layout/Layout";
import ClientDetails_BusinessInfo from './components/ClientDetails_business';
import ClientDetail_DBA from './components/ClientDetails_DBA';
import ClientDetails_Banks from './components/ClientDetails_Banks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import clientService from '../../services/client.service';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchclientsById } from '../../feature/client/client';
import chatsService from '../../services/chats.service';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { getUserIdFromToken } from '../../utils/decodeToken';


const Clientdetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  // console.log(location.pathname)
  // const clients = useSelector((state) => state.client);
  // console.log(clients)
  const navigate = useNavigate()
  const [clientDetails, setclientDetails] = useState([])
  const [Threads, setThreads] = useState([])
  const [searchKeyword, setsearchKeyword] = useState("")
  const [currentPage, setcurrentPage] = useState(1)
  const[userId,setuserId]=useState("")





  useEffect(()=>{
    const userId = getUserIdFromToken();
    setuserId(userId)

  },[])



  // const clientids = JSON.parse(clientIds);
  const toolbar = (
    <>
      <div className="input-group input-group-sm mb-3" style={{ width: 'auto' }}>
        <input autoComplete="off" type="text" id="search_clients" className="form-control" style={{ borderColor: '#1E468E' }} placeholder="Search by Name, EIN, City" aria-label="Search" aria-describedby="button-addon2" />
      </div>
    </>
  );
  const fetchClient = useCallback(async () => {
    try {

      const clients = await dispatch(
        fetchclientsById({
          id
        }) 
      ).unwrap();

   console.log(clients)
   localStorage.setItem('Userfolder', clients.foldername);
   setclientDetails(clients)

    } catch (err) {
      console.log(err);
    }
  }, []);

  const fetchThreads = async () => {
    try {
      const Threads = await chatsService.getAllThreads(searchKeyword, currentPage, userId, id)


      setThreads(Threads.threads)
      console.log(Threads.threads)



    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {

    fetchClient()
    fetchThreads()

  }, [])
    
const handleChange_User=()=>{
  navigate(`${location.pathname}/Client_portal_user`)
}
const handleChange_file=()=>{
  navigate(`${location.pathname}/Client_portal_file`)
}



  const clientHeaderToolbar =  (
    
      <>
        <button className='btn btn-outline-primary btn-sm' style={{ marginRight: '10px' }} onClick={() => navigate(`/chats?clientId=${id}`, { state: { clientId: id } })}>Client Chats & Tasks</button>
        <button className='btn btn-outline-primary btn-sm' style={{ marginRight: '10px' }} onClick={handleChange_User}>Client Portal Users</button>
        <button className='btn btn-outline-primary btn-sm' onClick={handleChange_file}>Files</button>


      </>
    );
  

  const clientHeaderDetails = (clientDetails) => {

    return (
      <>
        <div className='ms-2 fw-semibold'>{clientDetails.registered_agent_address}</div>
        <div className="structure text-black-50">{clientDetails.businessStructureTitle}</div>
      </>
    );
  }


  return (

    <Layout title={clientDetails.name} subtitle={clientHeaderDetails(clientDetails)} toolbar={clientHeaderToolbar}>


     <hr className='mt-0'/>

        <div className="row mb-5 gap-5">
          <div className="client-details col w-50 " >
            <div className='mb-5'><ClientDetails_BusinessInfo /></div>
            <div className='mb-5' ><ClientDetail_DBA /></div>
            {/* <div className='mb-5' ><Clientdetail_Banks /></div> */}
          </div>
          <div className="client_tasks col w-50" >
            <TaskDataTableComponent />
            <ChatsDataTableComponent Threads={Threads} userId={userId} />
          </div>
        </div>

    </Layout>
  )
}

export default Clientdetails