import axios from 'axios';
const API_URL = `${process.env.REACT_APP_API_URL}/admin/api/clients/`;
const getContactDetails= async (id ) => {

    const res = await axios.get(API_URL + "contact/"+id)

    return res.data;

}



const contactService = {
 
    getContactDetails
   
  }
  
  export default contactService;