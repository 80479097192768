import React, { useState } from "react";
import FileManagerService from "../../../services/FileManager.service";
import { setLoading } from "../../../feature/loading/loading";
import { setMessage } from "../../../feature/message/message";
import message from "../../../utils/message";
import { useDispatch, useSelector } from "react-redux";
import Error from "../../../components/Error";
import { validateFolderSelection } from "../../../utils/validation";



const AddFiles = ({Folder,selectedFileToAdd,handleCloseAddFile}) => {


  const loadingmessage = useSelector((state) => state.message.message);
  const isLoading = useSelector((state) => state.loading.loading);

    const [selectedFolder, setSelectedFolder] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const dispatch = useDispatch();


    const handleFolderChange = (e) => {
      setSelectedFolder(e.target.value);
     
    };

const AddFileToFolder=async()=>{
if (!validateFolderSelection(selectedFolder)) {
        setErrorMessage("Please select a folder before saving.");
        return;
      }
    const data ={
        file_ids:selectedFileToAdd
    }
try{
 dispatch(setLoading(true));
    dispatch(setMessage(message.loading));
await FileManagerService.AddFileToFolder(selectedFolder,data)
dispatch(setMessage(message.fileMoveFolder));
handleCloseAddFile()
}catch(err){
    console.log(err)
} finally {
    setTimeout(() => {
      dispatch(setLoading(false));
    }, 3000);
  }
}


  return (
    <>

  
 { Folder.length>0? (<div className="files-list dashboard">
    <label htmlFor="folderSelect">Select Folder</label>
    <select
      id="folderSelect"
      className="form-control"
      value={selectedFolder}
      onChange={handleFolderChange}
    >
      <option value="">-- Select a folder --</option>
      {Folder.length > 0 &&
        Folder.map((folder) => (
          <option key={folder.id} value={folder.id}>
            {folder.name} 
          </option>
        ))}
    </select>
  {errorMessage&&  <Error  error={errorMessage} />}

    <button className="btn btn-primary mt-3" onClick={AddFileToFolder}  disabled={isLoading}>
        {/* Save Files */}
        {isLoading ? (loadingmessage) : ("Save Files")}
      </button>
  </div>):(<div>No Folders found</div>) }
  </>
  );
};

export default AddFiles;
