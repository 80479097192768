import React from 'react';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getAllclients } from '../../feature/client/client';
import Select from 'react-select';

const ChatHeader = ({ unreadCount, searchKeyword, changeSearch, handleShow,handleSelectChange,client_id,clientList,handleInputChangeclient,options }) => {
  // const client = useSelector(getAllclients)
 
// console.log(client_id)
  return (
  
    <div className="chats-header">
      <div className='container-fluid '>

        <div className='chats-header__left unread_count'>{unreadCount} unread messages</div>

        <div className='chats-header__right toolbar'>
        <div className='company-filter'>
        <Select
     
        options={options}
        onInputChange={handleInputChangeclient}
        onChange={handleSelectChange}
        placeholder="Search and select clients..."
      />
          </div>
          <div className='search'>
            <InputGroup className="">
              <FormControl
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={searchKeyword}
                onChange={changeSearch}
                style={{ boxShadow: 'none' }}
                className='form-control form-control-sm'
              />

            </InputGroup>
          </div>
        </div>
        <div className='new-chat-btn'>
          <Button className="btn btn-sm" onClick={handleShow}>New Chat</Button>
        </div>


      </div>
    </div>
  );
};

export default ChatHeader;
