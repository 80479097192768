const message={
    loading: "Please wait...",
    dataNotFound: "No data found.",
    filesNotFound: "No files found for this client",
    uploading:"Uploading...",
    fileSaved:"File Saved",
    fileMoveFolder:"File move to folder",
    fileDeleted:"File delete",
    fileRecover:"File recover",
    folderCreated:"Folder created",
    folderDeleted:"Folder deleted",
    fileRemoveFromFolder:"File Removed from folder",
    userAdded:"New User added"
    

}


export default message