import axiosInstance from "../utils/axiosInstance";


const API_URL = `${process.env.REACT_APP_API_URL}/admin/api/clients/`;



const getAllbankDetails = async ( id ) => {
   
    const res = await axiosInstance.get(API_URL + "bank/"+id,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;

}

const CreateBank = async ( id,data ) => {
   
const res = await axiosInstance.post(API_URL + "bank",data,{
headers: {
'Content-Type': 'application/json',
},})

    return res.data;

}


const bankService={
    getAllbankDetails,CreateBank
 

}

export default bankService