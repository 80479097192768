import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contactService from "../../services/contact.service";



let initialState = {
   contact: [],
 };


export const fetchcontact = createAsyncThunk(
    "fetchcontact/fetchfetchcontact",
    async ({id},thunkAPI) => {
     
      try {
        const data = await contactService.getContactDetails(id);
      //   thunkAPI.dispatch(setMessage(data.message));
     
        return data;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  );




  const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: { },
    extraReducers(builder) {
       


        builder.addCase(fetchcontact.fulfilled, (state, action) => {
     
       state.contact =  action.payload;
      

      })

      
    }
})

// export const getAllclients = (state) => state.client.clients;
// export const getdba = (state) => state.dba.dba;
export default contactSlice.reducer
  