import React, { useEffect, useState } from "react";
import Filemanger_uploadfiles from "./Filemanger_uploadfiles";

import {
  imgicon,
  foldericon,
  trashicon,
  pdficon,
  documenticon,
  removeIcon
} from "../../../utils/icons";
import { getFileIcon } from "../../../utils/fileIcon";
import { Modal } from "react-bootstrap";
import FileManagerService from "../../../services/FileManager.service";
import { formatDateTime } from "../../../utils/dateTimeFormat";
import PreviewFileModal from "./Filemanager_PreviewFileModal";
import FolderService from "../../../services/folder.service";
import { setLoading } from "../../../feature/loading/loading";
import { setMessage } from "../../../feature/message/message";
import message from "../../../utils/message";
import { useDispatch, useSelector } from "react-redux";
import { handleVisibilityChange } from "../../../utils/visible";
import { downloadFile } from "../../../utils/downloadFiles";


const Files = ({ files, FolderIdEdit, setActiveTab,folderDetails }) => {
  const userFolder = localStorage.getItem('Userfolder');

  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);

  const [fileVisibilities, setFileVisibilities] = useState({});

  // Sync fileVisibilities with the files prop whenever files change
  useEffect(() => {
    if (files && files.length > 0) {
      const visibilityMap = files.reduce((acc, file) => {
        acc[file.id] = file.visible;
        return acc;
      }, {});
      setFileVisibilities(visibilityMap);
    }
  }, [files]);

  const handleClose = () => setShow(false);


  const downloadExtensions = ['docx', 'xlsx',  'pptx'];
  
  const handleShow = (fileUrl,fileName,fileExtension) => {
   
    if (downloadExtensions.includes(fileExtension)) {
      downloadFile(fileUrl, fileName)
      
      
    }else{
    
      setShow(true);
      setSelectedFileUrl(fileUrl);
    }

    
    
  };


  const deleteFileToFolder = async (file_id) => {
    const data = {
      file_id: file_id
    }
    try {
      dispatch(setLoading(true));
      dispatch(setMessage(message.uploading));
      await FolderService.DeleteFileToFolder(FolderIdEdit, data)
      setActiveTab("Folders")

      dispatch(setMessage(message.fileRemoveFromFolder));
    } catch (err) {
      console.log(err)
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 3000);
    }



  }


  const handleImmediateVisibilityChange = async (fileId, currentVisibility) => {
    // Toggle the visibility in the local state immediately
    setFileVisibilities((prevVisibilities) => ({
      ...prevVisibilities,
      [fileId]: !currentVisibility,
    }));

    // Then call the utility function to update the server
    await handleVisibilityChange(fileId, currentVisibility);
  };


  return (
    <div className="list-table table-responsive">
      <table className="table table-hover table-sm mt-4">
        <thead>
          <tr>
            <th width="40%">Name</th>
            <th width="15%">Uploaded By</th>
            <th width="15%">Uploaded on</th>
            <th width="10%">Status</th>
            <th width="10%" className="text-center">Visible to Client</th>
            <th width="10%" className="text-center">Remove</th>
          </tr>
        </thead>
        <tbody>
          {files.length > 0 ? (
            files.map((file, index) => (
              <tr key={index}>
                <td>
                  <div className="d-flex gap-1 align-items-center">
                    <div role="button">
                    <img src={getFileIcon(file.file_name)} alt={`${file.file_name} Icon`} className="file-icon" />
                    </div>
                    <span role="button" onClick={() => handleShow(file.file_url,file.file_name,file.file_type)}>{file.file_name}</span>
                  </div>
                </td>
                <td>{file.uploaded_by}</td>
                <td>{file.uploaded_on}</td>
                <td>
                  <span className={`badge rounded-pill ${file.status.toLowerCase() === 'approved' ? 'bg-success' : 'bg-secondary'}`}>
                    {file.status.toUpperCase()}
                  </span>
                </td>
                <td className="text-center">
                  <div className="form-switch">
                    {/* <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      checked={file.visible == true}
                      onChange={()=> handleVisibility(file.id, file.visible)}
                    /> */}

                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      checked={fileVisibilities[file.id] == true}
                      onChange={() => handleImmediateVisibilityChange(file.id, fileVisibilities[file.id])}
                    />
                  </div>
                </td>
                <td align="center">
                  <img
                    role="button"
                    style={{ width: "20px" }}
                    title="Delete this file"
                    src={removeIcon}
                    alt="trash icon"
                    onClick={() => deleteFileToFolder(file.id)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">Data not found</td>
            </tr>
          )}
        </tbody>
      </table>

      <PreviewFileModal show={show} handleClose={handleClose} fileUrl={selectedFileUrl} />
    </div>

  );
};

export default Files;
