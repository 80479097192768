import axiosInstance from "../utils/axiosInstance";


const API_URL = `${process.env.REACT_APP_API_URL}/admin/api/clients/`;

const getDba = async ( id) => {
   
    const res = await axiosInstance.get(API_URL + "dba/"+id,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;

}


const SaveDba = async ( values) => {
   
    const res = await axiosInstance.post(API_URL + "dba", values,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;

}


const EditDba = async ( DbaId,values) => {
   
    const res = await axiosInstance.patch(API_URL + "dba/"+DbaId,values,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;

}

const dbaService={
    getDba,SaveDba,EditDba


}
export default dbaService