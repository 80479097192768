import React, { useEffect, useState } from "react";
import Files from "./components/Filemanager_Files";
import Layout from "../../components/layout/Layout";
import Folders from "./components/Filemanager_Folders";
import Trashed from "./components/Filemanager_TrashedFiles";
import FolderDeatils from "./components/Filemanager_folderDetails";


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Filemanger_uploadfiles from "./components/Filemanger_uploadfiles";
import Filemanager_createfolder from "./components/Filemanager_createfolder";
import Filemanager_editfolder from "./components/Filemanager_editfolder"
import io from "socket.io-client";
import FileManagerService from "../../services/FileManager.service";
import FolderService from "../../services/folder.service";
import { useParams } from "react-router-dom";
import { getUserIdFromToken } from "../../utils/decodeToken";
import { useDispatch, useSelector } from "react-redux";
import message from "../../utils/message";
import clientService from "../../services/client.service";
import AddFiles from "./components/Filemanager_addfile";
import { setMessage } from "../../feature/message/message";
import { setLoading } from "../../feature/loading/loading";
// const socket = io(process.env.REACT_APP_API_URL);


const inputStyle = {
  borderColor: "#1E468E",
  position: "relative",
  width: "100%",
};

const Filemanager = () => {
  const { id } = useParams();


  const [show, setShow] = useState(false);
  const [showFolderUploads, setshowFolderUploads] = useState(false)
  const [showFolderEdit, setshowFolderEdit] = useState(false)
  const [showAddFile, setshowAddFile] = useState(false)
  const [selectedFileToAdd, setselectedFileToAdd] = useState([])
  const [currentPage, setcurrentPage] = useState(1)
  const [currentSet, setCurrentSet] = useState(1);

  const [TotalPage, setTotalPage] = useState("")
  const [FolderIdEdit, setFolderIdEdit] = useState("")

  const handleShowFolderUplaod = () => setshowFolderUploads(true)
  const handleCloseFolderUplaod = () => setshowFolderUploads(false)

  const handleShowFolderEdit = () => setshowFolderEdit(true)


  const handleCloseFolderEdit = () => setshowFolderEdit(false)



  const handleShowAddFile = (id) => {
    console.log(id)
    setselectedFileToAdd((prevSelectedFiles) => [...prevSelectedFiles, id]);
    getFolder()

    setshowAddFile(true)
  }
  const handleCloseAddFile = () => setshowAddFile(false)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [searchKeyword, setsearchKeyword] = useState("")
  const [searchKeywordFile, setsearchKeywordFile] = useState("")

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("Files"); // Default tab

  const [files, setfiles] = useState([])
  const [Folder, setFolder] = useState([])
  const [FolderDetails, setFolderDetails] = useState([])
  const [TrashedFile, setTrashedFile] = useState([])
  const [FileStatus, setFileStatus] = useState([])
  const [FolderName, setFolderName] = useState("")
  const [clientName, setclientName] = useState("")
  const [folderName, setfolderName] = useState("")

  // console.log(TrashedFile)
  const handleChangeSearch = (e) => {
    setsearchKeyword(e.target.value)
  }


  const handlesearchFile = (e) => {
    setsearchKeywordFile(e.target.value)



  }



  const toolbar = (
    <>
      <div className="file-manager-toolbar" >

        {files.length > 0 && <div className="col-auto">
          <button type="button" className="btn btn-outline-primary me-2" onClick={handleShowFolderUplaod}>
            New Folder
          </button>
        </div>}

        <div className="col-auto">
          <button type="button" className="btn btn-primary" onClick={handleShow}>
            Upload File
          </button>
        </div>
      </div>
    </>
  );





  const getFiles = async () => {
    const data = {
      search: searchKeyword,
      clientId: id,
      page: currentPage,
      limit: 25
    }
    try {
      const files = await FileManagerService.getFile(data)
      setcurrentPage(files.currentPage)
      setTotalPage(files.totalPages)
      setfiles(files.files)



    } catch (err) {
      setfiles([])
      console.log(err)
    }
  }



  const getFolder = async () => {
    try {

      const folder = await FolderService.FolderList(id, searchKeyword, currentPage)

      setFolder(folder.data)
      setcurrentPage(folder.currentPage)
      setTotalPage(folder.totalPages)
    } catch (err) {
      setFolder([])
      console.log(err)
    }
  }


  const trashedFile = async () => {
    const data = {
      clientId: id,
      search: searchKeyword,
      page: currentPage,
      limit: 25
    }
    try {
      const files = await FileManagerService.TrashedFile(data)
      setcurrentPage(files.currentPage)
      setTotalPage(files.totalPages)

      setTrashedFile(files.files)
    } catch (err) {
      setTrashedFile([])
      console.log(err)
    }
  }


  const folderDetails = async (folder_id, name) => {
    setFolderName(name)
    setFolderIdEdit(folder_id)
    const data = {
      searchKeyword: searchKeyword,
      clientId: id,
      folderId: folder_id,
      page: currentPage,
      limit: 25
    }
    try {
      const FolderDetails = await FileManagerService.getFile(data)
      // console.log(FolderDetails)
      setFolderDetails(FolderDetails.files)
    } catch (err) {
      console.log(err)
    }
  }



  const DeleteFolder = async (folder_id) => {
    try {
      dispatch(setLoading(true));
      dispatch(setMessage(message.uploading));
      await FolderService.DeleteFolder(folder_id)

      dispatch(setMessage(message.folderDeleted));
    } catch (err) {
      console.log(err)
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 3000);
    }
  }

  const AllFiles = async (folder_id) => {
    const data = {
      clientId: id,
      limit: 10
    }
    try {
      const file = await FolderService.EditFolder(folder_id, data)

      setFileStatus(file.data)
    } catch (err) {
      console.log(err)
    }
  }






  const fetchClientUser = async () => {
    try {

      const user = await clientService.getClientById(id)

      setclientName(user.name)
      setfolderName(user.foldername)
    } catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {

    if (activeTab === "Files") {
      getFiles()

    }
    if (activeTab === "Folders") {
      getFolder()

    }
    if (activeTab === "Trashed") {
      trashedFile()

    }
    fetchClientUser()
  }, [searchKeyword, activeTab, currentPage])




  return (
    <section className="filemanager-wrapper">
      <div className="file-manager">
        <Layout title={clientName} toolbar={toolbar} subtitle="FILES MANAGER">
          {files.length > 0 ? (<div className="card ">
            <div className="card-body">
              <div className="file-manager_header d-flex justify-content-between">
                <div className="header-left">
                  {activeTab === "FolderDetails" ? (
                    <div className="chip">
                      <span className="badge bg-primary fs-6">Folder: {FolderName}

                        <span
                          className="chip-close-icon"
                          onClick={() => setActiveTab("Folders")}
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                        >
                          &times;
                        </span>

                      </span>

                    </div>
                  ) : (
                    <div className="tab-navigation">
                      <div className={`tab-item ${activeTab === "Files" ? "active" : ""}`} onClick={() => setActiveTab("Files")}>
                        Files
                      </div>
                      <div
                        className={`tab-item ${activeTab === "Folders" ? "active" : ""}`}
                        onClick={() => setActiveTab("Folders")}
                      >
                        Folders
                      </div>
                      <div
                        className={`tab-item ${activeTab === "Trashed" ? "active" : ""}`}
                        onClick={() => setActiveTab("Trashed")}
                      >
                        Trashed
                      </div>

                    </div>)}
                </div>

                <div className="header-right">
                  {files.length > 0 && <input
                    autoComplete="off"
                    type="text"
                    id="search_clients"
                    className=" form-control border-secondary "
                    placeholder="Search files"
                    aria-label="Search"
                    aria-describedby="button-addon2"
                    style={inputStyle}
                    onChange={handleChangeSearch}
                  />}
                </div>






                {/* <div className="file-manager__right unread_count">54 Files</div> */}
              </div>
              <div className="file-manager__list">
                {activeTab === "Files" && <Files files={files} getFiles={getFiles} trashedFile={trashedFile} folderName={folderName} handleShowAddFile={handleShowAddFile}

                  currentPage={currentPage}
                  TotalPage={TotalPage}
                  currentSet={currentSet}
                  setCurrentSet={setCurrentSet}
                  setcurrentPage={setcurrentPage}



                />}
                {activeTab === "Folders" && <Folders Folder={Folder} folderDetails={folderDetails} setActiveTab={setActiveTab} DeleteFolder={DeleteFolder} getFolder={getFolder} handleShowFolderEdit={handleShowFolderEdit}

                  currentPage={currentPage}
                  TotalPage={TotalPage}
                  currentSet={currentSet}
                  setCurrentSet={setCurrentSet}
                  setcurrentPage={setcurrentPage}
                  setFolderIdEdit={setFolderIdEdit}

                />}
                {activeTab === "Trashed" && <Trashed TrashedFile={TrashedFile} getFiles={getFiles} trashedFile={trashedFile} folderName={folderName}

                  currentPage={currentPage}
                  TotalPage={TotalPage}
                  currentSet={currentSet}
                  setCurrentSet={setCurrentSet}
                  setcurrentPage={setcurrentPage}



                />}
                {activeTab === "FolderDetails" && <FolderDeatils files={FolderDetails} FolderIdEdit={FolderIdEdit} setActiveTab={setActiveTab}  folderDetails={folderDetails}/>}
              </div>

            </div>
            {/* </div> */}




          </div>) : (<div className="no-data-found "> {message.filesNotFound} </div>)}
        </Layout>
      </div>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Upload File(s)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Filemanger_uploadfiles setShow={setShow} handleClose={handleClose} getFiles={getFiles} />
        </Modal.Body>
      </Modal>

      <Modal show={showFolderUploads} onHide={handleCloseFolderUplaod} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Create Folder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Filemanager_createfolder files={files} setActiveTab={setActiveTab} getFolder={getFolder} handleCloseFolderUplaod={handleCloseFolderUplaod} handlesearchFile={handlesearchFile} />
        </Modal.Body>
      </Modal>

      <Modal show={showFolderEdit} onHide={handleCloseFolderEdit} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Update files in folder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Filemanager_editfolder setshowFolderEdit={setshowFolderEdit}
            FolderIdEdit={FolderIdEdit}
            getFolder={getFolder}
            id={id}

          />
        </Modal.Body>
      </Modal>


      <Modal show={showAddFile} onHide={handleCloseAddFile} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Files to folder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddFiles Folder={Folder} selectedFileToAdd={selectedFileToAdd} handleCloseAddFile={handleCloseAddFile} />
        </Modal.Body>
      </Modal>


    </section>
  );
};

export default Filemanager;
