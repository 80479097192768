import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import TaxAccountService from "../../services/taxAccount.service";




let initialState = {
TaxAccount: [],
 };


export const fetchTaxAccount = createAsyncThunk(
    "TaxAccount/fetchTaxAccount",
    async ({id},thunkAPI) => {
     
      try {
        const data = await TaxAccountService.getAccountDetails(id);
      //   thunkAPI.dispatch(setMessage(data.message));
     
        return data;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  );




  const TaxAccountSlice = createSlice({
    name: 'fetchTaxAccount',
    initialState,
    reducers: { },
    extraReducers(builder) {
       


        builder.addCase(fetchTaxAccount.fulfilled, (state, action) => {
     
       state.TaxAccount =  action.payload;
      

      })

      
    }
})

// export const getAllclients = (state) => state.client.clients;
// export const getdba = (state) => state.dba.dba;
export default TaxAccountSlice.reducer
  