import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { Container, Row, Col, Card, Button, Accordion, } from 'react-bootstrap';
import { formatDateTime } from '../../../utils/dateTimeFormat';

// import { Link, Navigate, useNavigate } from "react-router-dom";


const ChatsDataTableComponent = ({Threads,userId}) => {
  const data = [
    {
      date: '12/22/24 10:15am',
      detail: 'Request for W-2 Form....',
      startedby: 'Anjana',
      lastreply: 'Client',
      attachments: 'Client',
    },
    {
      date: '02/10/24 9:00am',
      detail: 'Bank Statement & Download',
      startedby: 'Noureen Lalani',
      lastreply: 'Client',
      attachments: '',
    },
    {
      date: '02/10/24 9:30am',
      detail: 'Bank Statement & Download-2',
      startedby: 'Noureen Lalani',
      lastreply: 'Client',
      attachments: '',
    },
    {
      date: '02/10/24 10:00am',
      detail: 'Bank Statement & Download',
      startedby: 'Noureen Lalani',
      lastreply: 'Client',
      attachments: '',
    },
    {
      date: '02/10/24 10:30am',
      detail: 'Bank Statement & Download',
      startedby: 'Noureen Lalani',
      lastreply: 'Client',
      attachments: '',
    },
  ];

  return (
    <div>
      <h5 className='mt-4'>Chats</h5>
      <Card className=" chats">
        <Card.Body>
          <div className="client_chats col">
            <div className="chats">
              <div className="card-body">
                <div className="dataTables_wrapper no-footer">
      
                  <table className="table table-hover">
                    <thead>
                      <tr style={{ fontSize: '80%' }}>
                        <th className="no-sort no-search" width="5"></th>
                        <th>DATE</th>
                        <th>Subject</th>
                       
                        <th>LAST REPLY BY</th>
                   
                      </tr>
                    </thead>
                    <tbody>
                      {Threads.map((thread, index) => (
                        <tr
                          key={index}
                          style={{ backgroundColor:'white'}}
                          title="Task Expired"
                        >
                          <td></td>
                          <td className="" style={{fontSize:"14px"}}>
                            {/* <a href="#" onClick={() => alert('Show Task Details')}> */}
                            {formatDateTime(thread.thread.updated_at)}
                            {/* </a> */}
                          </td>
                          <td style={{fontSize:"14px"}} >{thread.thread.subject}</td>
                         
                         

                          {thread.thread.participants.map((participant) => (
                            <td style={{fontSize:"14px"}}  key={participant.id}>
                                {participant.id != userId && participant.name}
                            </td>
                        ))}
                       
                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ChatsDataTableComponent;


