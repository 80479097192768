import React, { useEffect, useState } from 'react'
import { Card,  Accordion, } from 'react-bootstrap';
import loginsService from '../../../services/logins.service';
import { useParams } from 'react-router-dom';

const ClientDetails_Login = () => {
  const { id } = useParams();
const [logins,setlogins]=useState([])

const loginsDetails=async()=>{
  try{
const logins=await loginsService.getloginsDetails(id) 
setlogins(logins.logins)
// console.log(logins.logins)
  }catch(err){
    console.log(err)
  }

}


useEffect(()=>{
  loginsDetails()
},[])


  return (
    <>

 
 { logins.length!=0 && <Accordion.Item eventKey="7">
      <Accordion.Header>
        <div className='d-flex align-items-center w-50 justify-content-between   ' >
          <span className='fs-6 fw-medium'>Login</span>
          <div><button className='btn btn-xxs btn-outline-primary px-4 '> Add </button></div>
        </div>
      </Accordion.Header>
      <Accordion.Body className='' >
        { logins.map((loginsDetails)=>(
        <Card>
          <Card.Body>
            <div className="info-item">
              <span className="label">TYPE:</span>
              <span className="value"> {loginsDetails.loginType}</span>
            </div>
            <div className="info-item">
              <span className="label">USER ID:</span>
              <span className="value">{loginsDetails.login.user_id}</span>
            </div>
            <div className="info-item">
              <span className="label">PASSWORD:</span>
              <span className="value">{loginsDetails.login.password}</span>
            </div>
          </Card.Body>
          <i className="bi bi-pencil-square edit-icon "></i>
        </Card>
        )) }

      </Accordion.Body>
    </Accordion.Item>}
    </>
  )
}

export default ClientDetails_Login