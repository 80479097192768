import unreadIcon from '../assets/images/unread_icon.svg'
import expandIcon from "../assets/images/expandwindow_icon.svg"
import notificationIcon from "../assets/images/notification_white_icon.svg"

import foldericon from "../assets/images/addToFolderIcon.svg"
import documenticon from "../assets/images/google_icons/document.svg"
import imgicon from "../assets/images/google_icons/image.svg"
import pdficon from "../assets/images/google_icons/pdf.svg"
import trashicon from "../assets/images/trashIcon.svg"
import folders from "../assets/images/folderIcon.png"
import recover from "../assets/images/recover.svg"
import excelicon from "../assets/images/google_icons/excel.svg"
import dragdropIcon from "../assets/images/dragdropfiles.png"
import removeIcon from "../assets/images/remove.svg"


export {unreadIcon,expandIcon,notificationIcon,foldericon ,documenticon ,imgicon ,pdficon,trashicon ,folders,recover,excelicon,
    dragdropIcon,
    removeIcon
 }



