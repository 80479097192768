import React, { useEffect, useState } from 'react'
import { Card,  Accordion, } from 'react-bootstrap';
import shareholderService from '../../../services/shareholder.service';
import { useParams } from 'react-router-dom';

export const ClientDetails_ShareHolder = () => {
  const { id } = useParams();
  const[shareHolderDetails,setshareHolderDetails]=useState([])

  const getShareholderDetails=async()=>{
    try{
const shareholder=await shareholderService.getShareholderDetails(id)
// console.log(shareholder)
setshareHolderDetails(shareholder.shareholderDetails)
    }catch(err){
        console.log(err)
    }
}
useEffect(()=>{
  getShareholderDetails()
},[])
  return (
    <>


 
   {shareHolderDetails!=0&& <Accordion.Item eventKey="5">
      <Accordion.Header>
        <div className='d-flex align-items-center w-50 justify-content-between   ' >
          <span className='fs-6 fw-medium'>Shareholders</span>
          <div><button className='btn btn-xxs btn-outline-primary px-4 '> Add </button></div>
        </div>
      </Accordion.Header>
      <Accordion.Body className='' >
       { shareHolderDetails.map((shareHolderDetails)=>(
        <Card className='mb-2'>
          <Card.Body>
            <div className="info-item">
              <span className="label">Name</span>
              <span className="value">{`${shareHolderDetails.name}  ${shareHolderDetails.surname}`}</span>
            </div>
            <div className="info-item">
              <span className="label">Percentage</span>
              <span className="value">50.00%</span>
            </div>
            <div className="info-item">
              <span className="label">SSN</span>
              <span className="value">{shareHolderDetails.ssn}</span>
            </div>
            <div className="info-item">
              <span className="label">Address</span>
              <span className="value">{shareHolderDetails.address}</span>
            </div>
          </Card.Body>
          <i className="bi bi-pencil-square edit-icon "></i>
        </Card>
       ))}

      </Accordion.Body>
    </Accordion.Item>}
    </>
  )
}
