import React, { useEffect, useState } from 'react'
import { Card , Accordion, } from 'react-bootstrap';
import getaddressDetailsService from '../../../services/Address.service';
import { useParams } from 'react-router-dom';

const ClientDetails_Address = () => {
  const { id } = useParams();
const [Address,setAddress]=useState([])


const AddressDetails=async()=>{
  try{
const address=await getaddressDetailsService.getaddressDetails(id)
setAddress(address.Addresses)
// console.log(address.Addresses)
  }catch(err){
    console.log(err)
  }
}

useEffect(()=>{
  AddressDetails()
},[])
  return (
    <>

  
  {Address!=0&& <Accordion.Item eventKey="3">
    <Accordion.Header>
      <div className='d-flex align-items-center w-50 justify-content-between   ' >
        <span className='fs-6 fw-medium'>Address</span>
        <div><button className='btn btn-xxs btn-outline-primary px-4 '> Add </button></div>
      </div>
    </Accordion.Header>
    <Accordion.Body className=''>
     {Address.map((AddressDetails)=>(
      <Card className='mb-2'>
        <Card.Body>
          <div className="text-muted text-uppercase" style={{ fontSize: '12px' }}>{AddressDetails.addressType}</div>
          <div>{AddressDetails.address.address_line_1}</div>
        </Card.Body>
        <i className="bi bi-pencil-square edit-icon "></i>
      </Card>
     )) 
}
    </Accordion.Body>
  </Accordion.Item>}
  </>
  )
}

export default ClientDetails_Address