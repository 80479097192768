import axios from 'axios';
const API_URL = `${process.env.REACT_APP_API_URL}/api/admin/v1/users/`;
const getUserDetails = async (searchQuery,currentPage ) => {

    const res = await axios.get(API_URL + "getUserDetails",{  params: {
        search: searchQuery,
        page:currentPage
    }})

    return res.data;

}


const get_User_last_login = async (data ) => {

    const res = await axios.post(API_URL + "get_Client_login",data)

    return res.data;

}


const Create_client_portal = async (data ) => {

    const res = await axios.post(API_URL + "createClientPortal",data)

    return res.data;

}



const Update_user_status = async (id,data ) => {

    const res = await axios.post(API_URL + "updateStaus/"+id,data)

    return res.data;

}
const Delete_user = async (id ) => {

    const res = await axios.delete(API_URL + "clientPortalUser/"+id)

    return res.data;

}


const Update_user = async (id,data ) => {

    const res = await axios.patch(API_URL + "clientPortalUser/"+id,data)

    return res.data;

}
const UserService = {
 
    getUserDetails,get_User_last_login,Create_client_portal,Update_user_status,Delete_user,Update_user
   
  }
  
  export default UserService;