import axios from 'axios';
const API_URL = `${process.env.REACT_APP_API_URL}/admin/api/clients/`;
const getAccountDetails= async (id ) => {

    const res = await axios.get(API_URL + "taxAccount/"+id)

    return res.data;

}



const TaxAccountService = {
 
    getAccountDetails
   
  }
  
  export default TaxAccountService;