import axios from "axios";

import Cookies from 'js-cookie';
const API_URL = `${process.env.REACT_APP_API_URL}/api/admin/v1/users/`;



const login = (email, password) => {
    // console.log(process.env.REACT_APP_API_URL)
    return axios
      .post(API_URL + "login", {
        email,
        password,
      })
      .then((response) => {
        // console.log(response.data.user.token.access.token)
    
        if (response.data.user.token) {


          const tokens = {
            accessToken: response.data.user.token.access.token,
            refreshToken: response.data.user.token.refresh.token
          };
          const secureFlag = window.location.protocol == 'https:' ? true : false;
          Cookies.set('admone', JSON.stringify(tokens), { 
            expires: 1 / 12, 
            path: '/',
            domain: process.env.REACT_APP_COOKIE_URL || undefined,
            secure: secureFlag,  // Set secure flag
          
          });

          

        }
        if (response.data.user.user) {


        
       
          localStorage.setItem('userName', response.data.user.user.name);

                  }
  
        return response.data;
      });
  };


  const AuthService = {
 
    login,
   
  }
  
  export default AuthService;