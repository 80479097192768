// ConfirmationModal.js
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Error from '../../../components/Error';

const ConfirmationModal = ({ show, handleClose, handleConfirm ,DeleteUserError}) => {
    return (
        <Modal show={show} onHide={handleClose} >
            <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete this? This action cannot be undone.</p>

       

          {DeleteUserError&&(<Error error={DeleteUserError}/>)}
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-secondary" onClick={handleClose}>Cancel</button>
                <button className="btn btn-outline-danger" onClick={handleConfirm}>Delete</button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal;
