
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PermissionService from "../../services/Roles_permission.service";


let initialState = {

roles:[]

}

export const fetchAllRoles = createAsyncThunk(
    "Roles/fetchAllRoles",
    async ({userId},thunkAPI) => {
     console.log(userId)
      try {
        const data = await PermissionService.getPermission(userId);
      
    
        return data;
      } catch (error) {
  
        console.log(error)
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        
        return thunkAPI.rejectWithValue();
      }
    }
  );




  const RolesSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: { },
    extraReducers(builder) {
       
        builder.addCase(fetchAllRoles.fulfilled, (state, action) => {
            //  console.log(action.payload)
         state.roles =  action.payload;
        //  state.limit = action.payload.limit;
        //  state.page = action.payload.page;
        //  state.totalPages = action.payload.totalPages;
        //  state.totalResults = action.payload.totalResults

        })

   

      
    }
})




export default RolesSlice.reducer