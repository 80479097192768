import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout/Layout'
import ChatHeader from './ChatHeader'
import { jwtDecode } from 'jwt-decode';
import chatsService from '../../services/chats.service';
import ChatList from './ChatList';
import ThreadDetails from './ThreadDetails';
import TaskList from './TaskList';
import NewChatModal from './NewChatModal';
import Pagination from './Pagination';
import { fetchAllclients, getAllclients } from '../../feature/client/client';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { getUserIdFromToken } from '../../utils/decodeToken';
// import io from "socket.io-client";
import { fetchAllThreads } from '../../feature/Thread/Threads';

// const socket = io(process.env.REACT_APP_API_URL);
const Chats = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const NotificationThreadId = location.state?.NotificationThreadId;
  const ParmsThreadId = searchParams.get('threadId');


  // console.log(NotificationThreadId)

  const [show, setShow] = useState(false);


  const handleShow = () => setShow(true);
  const [clientList, setclientList] = useState([])


  const [Threads, setThreads] = useState([])
  const [searchKeyword, setsearchKeyword] = useState("")
  const [tasks, setTasks] = useState([{ taskDetails: '', taskDone: false, taskDoneTime: null }]);
  // console.log(tasks)
  const [taskshow, settaskshow] = useState([])
  const [selectedUserId, setSelectedUserId] = useState(null);

  const [currentPage, setcurrentPage] = useState(1)
  const [currentSet, setCurrentSet] = useState(1);

  const [TotalPage, setTotalPage] = useState("")

  const [selectedClientId, setSelectedClientId] = useState(null);
  const [subject, setSubject] = useState('');
  const [newmessage, setnewMessage] = useState('');
  const [UserId, setUserId] = useState("")




  const [selectedClient, setSelectedClient] = useState("");
  // console.log(selectedClient)
  const [selectedThreadId, setSelectedThreadId] = useState(null);




  const [hasTasks, setHasTasks] = useState(false);
  const [showTasks, setShowTasks] = useState(true);
  const [client_id, setclient_id] = useState("");
  // console.log(client_id)
  const [showComposeError, setshowComposeError] = useState("")
  const [Chatmessage, setChatmessage] = useState([]);
  const [clientFoldername,setclientFoldername]=useState("")

  const [fetchNotificationBysocket, setfetchNotificationBysocket] = useState(false);
 

  const [unreadCount, setunreadCount] = useState(0)


  const dispatch = useDispatch();

  const handleClose = () => {


    setShow(false)

    resetComposeForm()
    setshowComposeError("")

  };


  const fetchClients = async (search_keyword) => {
    try {
      const clients = await dispatch(
        fetchAllclients({
          datas: { user_id: UserId },
          params: { search: search_keyword }
        })
      ).unwrap();

      const clientIds = clients.data.map(client => client.id);

      setclient_id(clientIds)
      setclientList(clients.data)
    } catch (err) {
      console.log(err)
    }

  }


  useEffect(() => {
    const userId = getUserIdFromToken();
    setUserId(userId)
    // fetchClients()

  }, []);





  const fetchThreads = async () => {
    const data = {
      clientIds: selectedClient,
      searchKeyword: searchKeyword,
      page: currentPage
    };
    try {


      const Threads = await dispatch(fetchAllThreads({ data })).unwrap()

      setThreads(Threads.threads)
      console.log(Threads.threads)
      setTotalPage(Threads.totalPages)
      setcurrentPage(Threads.currentPage)
      setTotalPage(Threads.totalPages)

      setunreadCount(Threads.unreadMessageCount)


    } catch (err) {
      setThreads([])
      console.log(err)
    }
  }


  const fetchThreadMessage = async (thread_id) => {
    const data = {
      thread_id: thread_id
    }
    try {
      const SelectedThreadMessages = await chatsService.getMessage(thread_id, data)
      setclient_id(SelectedThreadMessages.client_id)
      setSubject(SelectedThreadMessages.subject)
      setChatmessage(SelectedThreadMessages.messages)
      settaskshow(SelectedThreadMessages.tasks)
      if (SelectedThreadMessages.tasks) {
        setHasTasks(true)
      } else {
        setHasTasks(false)
      }

    } catch (err) {
      console.log(err)
    }
  }






  useEffect(() => {
    fetchThreads()
  }, [show, currentPage, selectedClient,searchKeyword])

  useEffect(() => {
    if (selectedThreadId) {
      fetchThreadMessage(selectedThreadId)
    }
  }, [selectedThreadId]);




  const changeSearch = (e) => {
    setsearchKeyword(e.target.value)
    // fetchThreads()
  }






  const handleRowClick = (thread) => {
    setSubject(thread.subject)
    setSelectedThreadId(thread.id)
    setclient_id(thread.client.id)
    setclientFoldername(thread.client.foldername)

    const queryParams = new URLSearchParams({
      threadId: thread.id,


    });
    navigate(`/chats?${queryParams.toString()}`);
  };



  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleMessageChange = (event) => {
    setnewMessage(event.target.value);
  };

  const handleTaskDetails = (index, event) => {
    const newTasks = [...tasks];
    newTasks[index].taskDetails = event.target.value;
    setTasks(newTasks);
  };
  const removeTaskField = (index) => {
    setTasks(tasks.filter((_, i) => i !== index));
  };
  const addTaskField = () => {
    setTasks([...tasks, { taskDetails: '', taskDone: false, taskDoneTime: null }]);
  };



  const resetComposeForm = () => {
    setSubject("")
    setTasks([{ taskDetails: '', taskDone: false, taskDoneTime: null }])
    setnewMessage("")
  }
  const sendMessage = async (files) => {

    if (tasks.length > 1) {
      const isTaskDetailsFilled = tasks.every(task => task.taskDetails.trim() !== '');

      if (!isTaskDetailsFilled) {
        setshowComposeError('Please fill in all task details before saving.')
        // console.log('Please fill in all task details before saving.');
        return;
      }
    }

    const data = {
      subject: subject,
      messageBody: newmessage,
      client_id: selectedClientId,
      tasks: tasks[0].taskDetails == "" ? ([]) : (tasks),
      recipient_id: selectedUserId,
      files:files.length>0?files:null
    }

   

    try {

 
   const newthread=   await chatsService.createNewThread(data)
   console.log("newthread",newthread)
   setSelectedThreadId(newthread.thread.id)
      fetchThreads()
      setShow(false)
      files=[]
      resetComposeForm()

    } catch (err) {
      console.log(err)
    }


  }


  const handleInputChangeclient = (inputValue) => {
    if (inputValue.length > 2) {
      fetchClients(inputValue);
    }
  };
  const handleSelectChange = (selectedOptions) => {
    if (selectedOptions.value == 'all') {
      setSelectedClient("")
      fetchThreads()
    } else {
      setSelectedClient(selectedOptions.value)
    }


  };

  const options = [
    { value: 'all', label: 'All' }, // Static "All" option
    ...(clientList && clientList.map(client => ({
      value: client.id,
      label: client.name,
    })))
  ];





  useEffect(() => {
    if (NotificationThreadId !== undefined) {
      setSelectedThreadId(NotificationThreadId);

    }

    if (ParmsThreadId !== undefined) {
      setSelectedThreadId(ParmsThreadId)

    }
  }, [NotificationThreadId != undefined, ParmsThreadId !== undefined])



  // useEffect(() => {
  //   if (fetchNotificationBysocket == true) {
  // fetchThreads()

  // setfetchNotificationBysocket(false);

  //   }
  // }, [fetchNotificationBysocket == true]);

  // socket.on("connects", () => {
  //   setfetchNotificationBysocket(true);
  //   console.log("Connected to socket.io server");
  // });

  // socket.on("notificationUpdate", () => {
  //   console.log("Notification update received:");
  //   setfetchNotificationBysocket(true);

  // });

  // socket.on("disconnects", () => {
  //   setfetchNotificationBysocket(false);

  // });




  return (
    <Layout title="Chats">
      <div className={`chats-wrapper card ${selectedThreadId ? 'with-thread-detials' : ''}`}>
        <div className={`chats ${selectedThreadId ? 'collapsed' : ''}`}>
          <ChatHeader
            unreadCount={unreadCount}
            searchKeywords={searchKeyword}
            changeSearch={changeSearch}
            handleShow={handleShow}
            handleSelectChange={handleSelectChange}
            handleInputChangeclient={handleInputChangeclient}
            options={options}
            client_id={selectedClient}
            clientList={clientList}

          />
          <div className='all-chats'>
            <ChatList
              threads={Threads}
              handleRowClick={handleRowClick}

              selectedThreadId={selectedThreadId}

            />
          </div>
          <Pagination
            currentPage={currentPage}
            TotalPage={TotalPage}
            currentSet={currentSet}
            setCurrentSet={setCurrentSet}
            setcurrentPage={setcurrentPage}
          />
        </div>

        {selectedThreadId && (
          <>
            <ThreadDetails
              threadId={selectedThreadId}
              subject={subject}
              tasks={taskshow}
              setTasks={settaskshow}
              hasTasks={hasTasks}
              setShowTasks={setShowTasks}
              showTasks={showTasks}
              fetchThreads={fetchThreads}
              Chatmessage={Chatmessage}
              fetchThreadMessage={fetchThreadMessage}
              client_id={client_id}
              clientFoldername={clientFoldername}

            />
            {hasTasks && (
              <TaskList
                tasks={taskshow}
                threadId={selectedThreadId}

                showTasks={showTasks}
              />
            )}
          </>
        )}

        <NewChatModal
          show={show}
          handleClose={handleClose}
          messageChange={handleMessageChange}
          subjectChange={handleSubjectChange}
          taskChange={handleTaskDetails}
          removeTaskField={removeTaskField}
          tasks={tasks}
          setSelectedUserId={setSelectedUserId}
          setSelectedClientId={setSelectedClientId}
          addTaskField={addTaskField}
          sendMessage={sendMessage}
          showComposeError={showComposeError}
          subject={subject}
          newmessage={newmessage}
          
         
        />
      </div>
    </Layout>
  )
}

export default Chats
