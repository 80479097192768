
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message/message.js";
import bankService from "../../services/bank.service.js";



let initialState = {
    banks: [],
    bank: {},
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 1,
    
  };


export const fetchbank = createAsyncThunk(
    "bank/fetchbank",
    async ({id},thunkAPI) => {
     
      try {
        const data = await bankService.getAllbankDetails(id);
      //   thunkAPI.dispatch(setMessage(data.message));
     
        return data;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  );
  
  export const Createbank = createAsyncThunk(
    "bank/createbank",
    async (value,thunkAPI) => {
     
      try {
        const data = await bankService.CreateBank(value);
      //   thunkAPI.dispatch(setMessage(data.message));
     
        return data;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  );


  const bankSlice = createSlice({
    name: 'bank',
    initialState,
    reducers: { },
    extraReducers(builder) {
       
        // builder.addCase(fetchAllclients.fulfilled, (state, action) => {
        //     //  console.log(action.payload)
        //  state.clients =  action.payload;
        // //  state.limit = action.payload.limit;
        // //  state.page = action.payload.page;
        // //  state.totalPages = action.payload.totalPages;
        // //  state.totalResults = action.payload.totalResults

        // })

        builder.addCase(fetchbank.fulfilled, (state, action) => {
        //  console.log(action.payload)
       state.banks =  action.payload;
      //  state.limit = action.payload.limit;
      //  state.page = action.payload.page;
      //  state.totalPages = action.payload.totalPages;
      //  state.totalResults = action.payload.totalResults

      })

      
    }
})

// export const getAllclients = (state) => state.client.clients;
export const bankdetails = (state) => state.bank.bank;
export default bankSlice.reducer
