import axios from 'axios';
import axiosInstance from '../utils/axiosInstance';
const API_URL = `${process.env.REACT_APP_API_URL}/api/admin/v1/`;
const getNotifications = async () => {

    const res = await axiosInstance.get(API_URL + "notification")

    return res.data;

}

const updateUnread = async (id ) => {

    const res = await axiosInstance.patch(API_URL + "notification")

    return res.data;

}
const  NotificationService={
    
    getNotifications,updateUnread
 

}

export default NotificationService