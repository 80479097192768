import { useCallback, useEffect, useState } from 'react';
import AddnewUser from "./Components/AddnewUser";
import ClientPortalUsersTable from "./Components/Users";
import Userdetails from "./Components/Userdetails";
import Layout from '../../components/layout/Layout'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOn } from "@fortawesome/free-solid-svg-icons";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { useParams } from 'react-router-dom';
import UserService from '../../services/user.service';
import shareholderService from '../../services/shareholder.service';
import contactService from '../../services/contact.service';
import { fetchclientsById } from '../../feature/client/client';
import { useDispatch } from 'react-redux';
import { getUserIdFromToken } from '../../utils/decodeToken';
import clientService from '../../services/client.service';
import { fetchClientPortalUsers } from '../../feature/ClientPortalUser/users';
import message from '../../utils/message';
import { setMessage } from '../../feature/message/message';
import { setLoading } from '../../feature/loading/loading';

function Clientportal() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const handleClose = () =>{
        
        setcreateUserError("")    
        setShow(false)
    
    };
    const handleShow = () => setShow(true);
    const [userDetais, setuserDetais] = useState([]);
    const [userLastLogin, setuserLastLogin] = useState([])
    const [shareHolderDetails, setshareHolderDetails] = useState([])
    const [contactDetails, setcontactDetails] = useState([])
    const [currentPage, setcurrentPage] = useState("1")
    const [totalPage, settotalPage] = useState("")
    const[createUserError,setcreateUserError]=useState("")
    const [searchQuery, setSearchQuery] = useState('');
    const [clientDetails, setclientDetails] = useState([])
 
    
    const get_Client_portal_User = async () => {
        try {

            // const user = await UserService.getUserDetails(searchQuery, currentPage)
            const user =await dispatch(fetchClientPortalUsers({searchQuery, currentPage})).unwrap()
            console.log(user)
            setuserDetais(user.users)
            settotalPage(user.totalPages)

            const user_id = user.users.map((user) => user.user_details.id)

            get_Last_Login(user_id)



        } catch (err) {
            console.log(err)
        }
    }


    const get_Last_Login = async (user_id) => {
        const data = {
            user_id: user_id
        }
        try {

            const lastLogin = await UserService.get_User_last_login(data)

            console.log(lastLogin)
            setuserLastLogin(lastLogin.userLogin)



        } catch (err) {
            console.log(err)
        }
    }


    const getShareholderDetails = async () => {
        try {
            const shareholder = await shareholderService.getShareholderDetails(id)
            // console.log(shareholder)
            setshareHolderDetails(shareholder.shareholderDetails
            )
        } catch (err) {
            console.log(err)
        }
    }

    const getContactDetails = async () => {
        try {
            const contact = await contactService.getContactDetails(id)
            // console.log(contact)
            setcontactDetails(contact.Contact)
        } catch (err) {
            console.log(err)
        }
    }




    useEffect(() => {
   
    
        get_Client_portal_User()
        getShareholderDetails()
        getContactDetails()
    }, [searchQuery, currentPage])


    const createUser = async (data) => {


        const Userdata = {
            name: data.firstName,
            surname: data.lastName,
            client_ids: data.client_ids,
            email: data.email,
            phone:data.mobile
        }

    
        try {
            dispatch(setLoading(true));
            dispatch(setMessage(message.loading));
          await UserService.Create_client_portal(Userdata)
            setShow(false)
         await get_Client_portal_User()
            dispatch(setLoading(true));
            dispatch(setMessage(message.userAdded));

        } catch (err) {
            console.log(err)
            setcreateUserError(err.response.data.error)
        }finally {
            setTimeout(() => {
              dispatch(setLoading(false));
            }, 3000);
          }

    }
    const AddNewToolbar =  (
    
        <>
          <button className='btn btn-outline-primary btn-sm' style={{ marginRight: '10px' }} onClick={handleShow}>Add new user</button>
        
  
        </>
      );

    return (
        <Layout title="Client Portal Users"  toolbar={AddNewToolbar}>


            <ClientPortalUsersTable 
                userDetails={userDetais} 
                userLastLogin={userLastLogin} 
                setSearchQuery={setSearchQuery} 
                currentPage={currentPage} 
                setcurrentPage={setcurrentPage} 
                totalPage={totalPage} 
                get_Client_portal_User={get_Client_portal_User} 
            />

            {/* Uncomment if you plan to use these components */}
            {/* <div className="col-6">
                        <AddnewUser />
                    </div>
                    <div className="col-6"></div>
                    <div className="col-6 mt-5">
                        <Userdetails />
                    </div> */}



            <Modal show={show} onHide={handleClose} size="lg" >
                <Modal.Header closeButton>
                    <Modal.Title>Add New User</Modal.Title>
                </Modal.Header>
                <Modal.Body className=''>
                    <AddnewUser  shareHolderDetails={shareHolderDetails} contactDetails={contactDetails} createUser={createUser} createUserError={createUserError} setShow={setShow} />
                </Modal.Body>
            </Modal>



        </Layout>
    );
}

export default Clientportal;
