import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message/message.js";
import clientService from "../../services/client.service";
import { setLoading } from "../loading/loading.js";
import message from "../../utils/message.js";


// import CategoryService from "../../services/category.service";

let initialState = {
    clients: [],
    client: {},
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 1,
    
  };

export const fetchAllclients = createAsyncThunk(
  "client/fetchAllclient",
  async ({datas,params},thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    thunkAPI.dispatch(setMessage(message.loading))
    try {
      const data = await clientService.getAllClient(datas,params);
    //   thunkAPI.dispatch(setMessage(data.message));
  
      return data;
    } catch (error) {

      console.log(error)
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }finally {
      thunkAPI.dispatch(setLoading(false)); 
    thunkAPI.dispatch(setMessage(""))

    }
  }
);



export const fetchclientsById = createAsyncThunk(
  "client/fetchclient",
  async ({id},thunkAPI) => {
    try {
     
      const data = await clientService.getClientById(id);
    //   thunkAPI.dispatch(setMessage(data.message));
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const updateClient= createAsyncThunk(
  "client/updateclient",
  async ({id,values},thunkAPI) => {
    try {
     
      const data = await clientService.updateClient(id,values);
    //   thunkAPI.dispatch(setMessage(data.message));
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);




const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: { },
    extraReducers(builder) {
       
        builder.addCase(fetchAllclients.fulfilled, (state, action) => {
            //  console.log(action.payload)
         state.clients =  action.payload;
        //  state.limit = action.payload.limit;
        //  state.page = action.payload.page;
        //  state.totalPages = action.payload.totalPages;
        //  state.totalResults = action.payload.totalResults

        })

        builder.addCase(fetchclientsById.fulfilled, (state, action) => {
        //  console.log(action.payload)
       state.client =  action.payload;
      //  state.limit = action.payload.limit;
      //  state.page = action.payload.page;
      //  state.totalPages = action.payload.totalPages;
      //  state.totalResults = action.payload.totalResults

      })

      
    }
})

export const getAllclients = (state) => state.client.clients;
export const getclient = (state) => state.client.client;
export default clientSlice.reducer
