
import Header from './Header'
import Loading from './Loading';
import PageTitle from './Page_Title';
import { useSelector } from 'react-redux';

const Layout = ({ title, subtitle="", toolbar="", children }) => {

    const isLoading = useSelector((state) => state.loading.loading);
   
    return (

        <>
            <Header />
            {isLoading && <Loading/>}
            <section id="page-content" className="main-content">
                <div className="container-fluid">
                <PageTitle title={title} subtitle={subtitle} toolbar={toolbar}></PageTitle>
                    {children}
                </div>
            </section>
        </>

    )
}

export default Layout