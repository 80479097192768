import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message/message.js";
import { setLoading } from "../loading/loading.js";
import message from "../../utils/message.js";
import UserService from "../../services/user.service.js";
export const fetchClientPortalUsers = createAsyncThunk(
    "clientportal/fetchAllUsers",
    async ({searchQuery, currentPage},thunkAPI) => {
      thunkAPI.dispatch(setLoading(true));
      thunkAPI.dispatch(setMessage(message.loading))
      try {
        const users = await UserService.getUserDetails(searchQuery, currentPage) ;
       
        return users;
      } catch (error) {
    
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message)||
          error.message ||
          error.toString();
    //  console.log(message)
    thunkAPI.dispatch(setMessage(message))
        return thunkAPI.rejectWithValue();
      }finally {
        thunkAPI.dispatch(setLoading(false)); 
      thunkAPI.dispatch(setMessage(""))
  
      }
    }
  );
  




  const ClientPortalUsersSlice = createSlice({
    name: 'clientPortalUsers',
    initialState:{
ClientPortalUsers:[],

    },
    reducers:{},
    extraReducers(builder) {
       
 

        builder.addCase(fetchClientPortalUsers.fulfilled, (state, action) => {
          state.ClientPortalUsers =  action.payload;
})



 }
})


export default ClientPortalUsersSlice.reducer



