import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Userdetails from './Userdetails';
import UserService from '../../../services/user.service';
import { foldericon, trashicon } from '../../../utils/icons';
import ConfirmationModal from './DeleteConfirmationBox';
import AddnewUser from './AddnewUser';

const User = ({ userDetails, userLastLogin, setSearchQuery, currentPage, setcurrentPage, totalPage, get_Client_portal_User }) => {
    // console.log(userDetails)
    const [show, setShow] = useState(false);
    const [user, setuser] = useState([])
    const [status, setstatus] = useState("")
    const [statusChange, setstatusChange] = useState(false)
    const [selectedUserId, setselectedUserId] = useState("")
    const [confirmationShow, setConfirmationShow] = useState(false);
    const [deleteUserId, setDeleteUserId] = useState("")
    const [DeleteUserError, setDeleteUserError] = useState("")
    const [UpdateUserError, setUpdateUserError] = useState("")

    const [IsEdit, setIsEdit] = useState(false)
    const [Details, setDetails] = useState([])
    // console.log(status)
    // console.log(selectedUserId)

    const [currentSet, setCurrentSet] = useState(1);
    //  const [pagesToShow, setPagesToShow] = useState(3);
    const maxPagesToShow = 3;
    const totalSets = Math.ceil(totalPage / maxPagesToShow);
    const handleClose = () => {

        setShow(false)
        setIsEdit(false)
    };

    const closeConfirmation = () => {
        setConfirmationShow(false)
        setDeleteUserError("")

    }
    const handleShow = (user) => {

        setuser(user)
        setShow(true)
    };
    const getLatestLoginTime = (userId) => {
        const user = userLastLogin.find((item) => item.userId === userId);

        if (!user || user.loginAttempts.length === 0) {
            return 'Never logged in';
        }
        // Return the first login time as it's already sorted
        return user.loginAttempts[0].loginTime;
    };
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handlePageChange = (page) => {
        setcurrentPage(page);

    };
    const renderPagination = () => {
        const pages = [];
        const startPage = (currentSet - 1) * maxPagesToShow + 1;
        const endPage = Math.min(currentSet * maxPagesToShow, totalPage);

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <a className="page-link" href="#!" onClick={() => handlePageChange(i)}>
                        {i}
                    </a>
                </li>
            );
        }

        return (
            <>
                {currentSet > 1 && (
                    <li className="page-item">
                        <a className="page-link" onClick={() => setCurrentSet(currentSet - 1)}>
                            Prev
                        </a>
                    </li>
                )}
                {pages}
                {currentSet < totalSets && (
                    <li className="page-item">
                        <a className="page-link" onClick={() => setCurrentSet(currentSet + 1)}>
                            Next
                        </a>
                    </li>
                )}
            </>
        );
    };

    const updateStatus = async () => {
        const data = {
            status: status
        }
        try {
            const status = await UserService.Update_user_status(selectedUserId, data)
            console.log(status)

        } catch (err) {
            console.log(err)
        }
    }


    useEffect(() => {

        if (statusChange) {


            updateStatus()
        }
    }, [statusChange])

    const renderClientNames = (clients) => {
        if (!clients || clients.length === 0) return null; // Handle cases where clients array is empty

        // Extract the first two client names
        const clientNames = clients.map((client) => client.name);


        // Join names with a comma separator
        const namesText = clientNames.join(', ');

        // Add "..." if there are more than two names
        // return clientNames.length > 2 ? `${namesText}...` : namesText;
        return namesText;
    };



    const handleTrashClick = (userId) => {
        // console.log(userId)
        setDeleteUserId(userId);
        setConfirmationShow(true);
    };

    const handleConfirmDelete = async () => {
        try {
            // Replace with actual delete logic
            await UserService.Delete_user(deleteUserId);
            setConfirmationShow(false);
            setShow(false)
            get_Client_portal_User()

        } catch (err) {
            console.log(err);
            setDeleteUserError(err.response.data.error)
        }
    };

    const HandleOpenEdit = (user) => {
        setIsEdit(true)
        setShow(true)
        setDetails(user)

    }

    const updateUser = async (id, clients_id) => {
        const data = {
            client_ids: clients_id
        }
        try {
            await UserService.Update_user(id, data);
            setShow(false)
            get_Client_portal_User()
        } catch (err) {
            console.log(err)
            setUpdateUserError(err.response.data.error)
        }
    }


    return (
        <>
            <div className="wrapper">
                
                    <div className="card list-table">
                        <div className="card-body">

                            <div class="table-info mb-4 d-flex justify-content-between align-items-center">
                                <small class="text-muted">Total {userDetails.length} users </small>

                                <div className="d-flex align-items-center">
                                    <label htmlFor="searchInput" className="col-form-label me-2">
                                        Search:
                                    </label>
                                    <input
                                        type="search"
                                        id="searchInput"
                                        className="form-control form-control-sm"
                                        aria-describedby="passwordHelpInline"
                                        onChange={handleSearchChange}
                                    />
                                </div>
                            </div>
                            {userDetails.length != 0 ? (
                                <>
                            <table className="table table-sm table-striped">
                                <thead>
                                    <tr className="text-start">
                                        <th scope="col">NAME</th>
                                        <th scope="col">EMAIL</th>
                                        <th scope="col">CLIENTS</th>

                                        <th scope="col">LAST LOGIN</th>
                                        <th scope="col">ACTIONS</th>


                                    </tr>
                                </thead>
                            { userDetails.length>0?(  <tbody className="table-group-divider text-start">
                                    {userDetails.map((user) => (
                                        <tr key={user.id}>
                                            <td className='fw-bold'>{`${user.user_details.name} ${user.user_details.surname}`}</td>
                                            <td >{user.user_details.email}</td>
                                            <td >
                                                {user.clients && user.clients.map((client, index) => (
                                                    <h3 key={index} className="badge me-2 fw-normal text-bg-secondary" style={{fontSize:"0.75rem"}}>
                                                        {client.name}
                                                    </h3>
                                                ))}

                                            </td>

                                            <td > {getLatestLoginTime(user.user_details.id)}</td>

                                            <td className="action ">


                                                <a className="text-decoration-none me-2" href="javascript:void(0)" onClick={() => HandleOpenEdit(user)}>
                                                    Edit
                                                </a>
                                                <a className="text-decoration-none" href="javascript:void(0)" onClick={() => handleShow(user.user_details)}>
                                                    Details
                                                </a>
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>):(<div>Data not found</div>) }
                            </table>


                            <nav aria-label="Page navigation ">
                                <ul className="pagination pagination-wrapper justify-content-end">
                                    {renderPagination()}
                                </ul>
                            </nav>
                            </>
                             ) : (<div>Data not available</div>)}
                        </div>
                    </div>

            </div>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{IsEdit ? ("Edit User") : ("User Details")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {IsEdit ? (<AddnewUser IsEdit={IsEdit} Details={Details} updateUser={updateUser} UpdateUserError={UpdateUserError} setShow={setShow} get_Client_portal_User={get_Client_portal_User} />) : (<Userdetails user={user} userLastLogin={userLastLogin} setstatus={setstatus} setselectedUserId={setselectedUserId} setstatusChange={setstatusChange} handleTrashClick={handleTrashClick} />)}
                </Modal.Body>
            </Modal>

            <ConfirmationModal
                show={confirmationShow}
                handleClose={closeConfirmation}
                handleConfirm={handleConfirmDelete}
                DeleteUserError={DeleteUserError}
            />
        </>
    );
};

export default User;
