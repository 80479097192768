import FileManagerService from "../services/FileManager.service";

export const handleVisibilityChange = async (fileId, currentVisibility, getFilesCallback) => {
    try {
      const newVisibility = currentVisibility ? 0 : 1;
      await FileManagerService.UpdateVisible(fileId, { is_visible: newVisibility });
      getFilesCallback();
    } catch (err) {
      console.error(err);
    }
  }