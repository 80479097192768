// utils/fileUtils.js

export const downloadFile = (fileUrl, fileName) => {

  

      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName; // Optional: this sets the downloaded file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    
  };
  