import '../../assets/sass/login.scss';
import Header from './Header'



const Layout = ({ children }) => {


    return (
       
            <div className="app">  
           
            
                    {children}
                   
                
            </div>
        
    )
}

export default Layout